import React from 'react';
import './animations.css'; // Import the CSS file

const features = [
  {
    title: 'Clarity',
    description: 'We help you make sense of the coins, the terms, the dense charts and market changes.',
    icon: '/images/clarity.svg',
  },
  {
    title: 'Confidence',
    description: 'Our markets are always up to date, sparking curiosity with real-world relevance.',
    icon: '/images/confidence.svg',
  },
  {
    title: 'Community',
    description: 'We support the crypto community, putting data in the hands which need it most.',
    icon: '/images/community.svg',
  },
];

const TrustedPlatform = () => {
  return (
    <section className="bg-[#131315] text-white py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="relative flex justify-center items-center w-full lg:w-1/2 mb-8 lg:mb-0 animate-left animate-delay-1">
            <img
              className="w-full h-auto max-w-xs lg:max-w-full"
              src="/images/planet.svg"
              alt="Global Network"
            />
          </div>
          <div className="flex flex-col w-full lg:w-1/2 lg:pl-12 animate-right animate-delay-2">
            <div className="text-center lg:text-left mb-8">
              <h2 className="text-3xl font-extrabold">We are the most trusted cryptocurrency platform.</h2>
              <p className="text-gray-400 mt-4">
                We believe {process.env.REACT_APP_NAME_LOW} is here to stay — and that a future worth building is one which opens its doors and invites everyone in.
              </p>
            </div>
            <div className="flex flex-col space-y-8">
              {features.map((feature, index) => (
                <div key={index} className={`flex items-center animate-top animate-delay-${index + 3}`}>
                  <div className="flex-shrink-0 h-12 w-12 mr-4">
                    <img src={feature.icon} alt={feature.title} className="h-full w-full" />
                  </div>
                  <div>
                    <h3 className="text-lg font-bold">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedPlatform;
