import React, { useState, useEffect, useCallback } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';
import { useWallet } from './WalletContext';
import Select, { components } from 'react-select';
import './Transfer.css';

const currencyOptions = [
    { value: 'btc', label: 'Bitcoin', icon: '/images/btc-logo.png' },
    { value: 'eth', label: 'Ethereum', icon: '/images/eth-logo.png' },
    { value: 'ltc', label: 'Litecoin', icon: '/images/ltc-logo.png' },
    { value: 'usdc', label: 'USD Coin', icon: '/images/usdc-logo.png' },
    { value: 'usdt', label: 'Tether', icon: '/images/usdt-logo.png' },
    { value: 'bnb', label: 'Binance Coin', icon: '/images/bnb-logo.png' },
    { value: 'doge', label: 'Dogecoin', icon: '/images/doge-logo.png' },
    { value: 'trx', label: 'Tron', icon: '/images/trx-logo.png' },
    { value: 'xrp', label: 'Ripple', icon: '/images/xrp-logo.png' },
];

// Custom SingleValue component to display the icon, label, balance, and USD value
const SingleValue = (props) => (
    <components.SingleValue {...props}>
        <div className="custom-single-value flex items-center">
        <img src={props.data.icon} alt={props.data.label} style={{ width: 36, height: 36, marginRight: 10 }} />
        <div className='block'>
                <p className='font-semibold'>{props.data.label}</p>
                <p className='text-base'>{(props.data.balance * 1).toFixed(8)}<span className='text-sm text-[#787f91]'> ≈ ${(props.data.balance * props.data.usdPrice).toFixed(2)}</span></p>
            </div>
        </div>
    </components.SingleValue>
);

// Custom Option component to display the icon and label
const Option = (props) => (
    <components.Option {...props}>
        <div className="custom-option flex cursor-pointer w-full items-center">
            <img src={props.data.icon} alt={props.data.label} style={{ width: 30, height: 30, marginRight: 10 }} />
            <div className='block'>
                <p>{props.data.label}</p>
                <p className='text-base font-normal'>{(props.data.balance * 1).toFixed(8)}<span className='text-sm text-[#787f91] font-normal'> ≈ ${(props.data.balance * props.data.usdPrice).toFixed(2)}</span></p>
            </div>
        </div>
    </components.Option>
);

const Transfer = () => {
    const { userData, prices } = useWallet();
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState(currencyOptions[0]);
    const [fromWallet, setFromWallet] = useState('funding');
    const [toWallet, setToWallet] = useState('trading');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const getBalance = useCallback((currency, walletType) => {
        if (!userData) return 0;
        return userData.wallets[walletType]?.[currency] || 0;
    }, [userData]);

    const updateCurrencyBalance = useCallback((walletType) => {
        setCurrency(prevCurrency => ({
            ...prevCurrency,
            balance: getBalance(prevCurrency.value, walletType),
            usdPrice: prices[prevCurrency.value] || 0
        }));
    }, [getBalance, prices]);

    useEffect(() => {
        // Update currency balance when fromWallet changes or on initial load
        updateCurrencyBalance(fromWallet);
    }, [fromWallet, updateCurrencyBalance]);

    const handleTransfer = async () => {
        setError('');
        setSuccess('');
        setButtonDisabled(true);

        if (fromWallet === toWallet) {
            setError('Cannot transfer to the same wallet');
            setButtonDisabled(false);
            return;
        }

        const user = auth.currentUser;
        if (!user) {
            setError('User not authenticated');
            setButtonDisabled(false);
            return;
        }

        const transferAmount = parseFloat(amount);
        if (isNaN(transferAmount) || transferAmount <= 0) {
            setError('Invalid amount');
            setButtonDisabled(false);
            return;
        }

        const fromBalance = getBalance(currency.value, fromWallet);
        if (fromBalance < transferAmount) {
            setError('Insufficient balance');
            setButtonDisabled(false);
            return;
        }

        try {
            const userRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userRef);

            if (!userDoc.exists()) {
                setError('User data not found');
                setButtonDisabled(false);
                return;
            }

            const userData = userDoc.data();
            const fromBalance = userData.wallets[fromWallet]?.[currency.value] || 0;
            const toBalance = userData.wallets[toWallet]?.[currency.value] || 0;

            const updatedFromBalance = fromBalance - transferAmount;
            const updatedToBalance = toBalance + transferAmount;

            await updateDoc(userRef, {
                [`wallets.${fromWallet}.${currency.value}`]: updatedFromBalance,
                [`wallets.${toWallet}.${currency.value}`]: updatedToBalance,
            });

            setSuccess('Transfer successful');
            updateCurrencyBalance(fromWallet);  // Update the balance after a successful transfer

            // Refresh the page after 1 second
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.error('Failed to transfer:', error);
            setError('Failed to transfer');
            setButtonDisabled(false);
        }

        // Re-enable the button after 2 seconds
        setTimeout(() => {
            setButtonDisabled(false);
        }, 2000);
    };

    return (
        <div className="p-2 md:p-14 bg-[#131315]">
            <div className='block md:flex items-center justify-between'>
                <div>
                    <h1 className='pb-0 md:pb-2 text-2xl md:text-3xl font-bold text-white'>Transfer</h1>
                    <p className="text-sm font-mediun text-[#787f91]">
                        On this page you can transfer crypto between your wallets
                    </p>
                </div>
            </div>
            <div className='flex justify-center rounded-2xl p-10 mt-6' style={{ border: '2px solid #17181c' }}>
                <div className='flex flex-col w-full gap-y-5 md:w-1/2'>
                    <div className='flex flex-col items-start w-full'>
                        <Select
                            options={currencyOptions.map(option => ({
                                ...option,
                                balance: getBalance(option.value, fromWallet),
                                usdPrice: prices[option.value] || 0,
                            }))}
                            isSearchable={false}
                            value={currency}
                            onChange={setCurrency}
                            components={{ SingleValue, Option }}
                            classNamePrefix='react-select'
                            className='react-select-container-transfer transition-colors duration-300 w-full'
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: '2px solid #3f3f46',
                                    borderRadius: '12px',
                                    backgroundColor: '#131315',
                                    color: '#fff',
                                    padding: '10px 5px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#131315',
                                    border: '2px solid #17181c',
                                    borderRadius: '1rem',
                                    boxShadow: 'inset 0 0 50px #131315',
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    fontWeight: '700',
                                    backgroundColor: state.isSelected ? 'unset' : state.isFocused ? 'unset' : 'unset',
                                    color: state.isSelected ? 'white' : '#787f91',
                                    '&:hover': {
                                        backgroundColor: 'unset',
                                        color: 'white'
                                    },
                                    padding: '0.75rem 0.75rem'
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: 'white',
                                }),
                                menuList: (provided) => ({
                                    ...provided,
                                    '::-webkit-scrollbar': { display: 'none' },
                                    '-ms-overflow-style': 'none',
                                    'scrollbar-width': 'none',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: 'unset',
                                    primary: 'unset',
                                    neutral0: 'unset',
                                    neutral80: 'unset',
                                },
                            })}
                        />
                    </div>
                    <div className='flex items-center gap-3'>
                        <div className='flex flex-col items-start w-full'>
                            <p className='text-white text-sm font-medium w-full'>From</p>
                            <select
                                disabled
                                value={fromWallet}
                                onChange={(e) => {
                                    setFromWallet(e.target.value);
                                    updateCurrencyBalance(e.target.value);
                                }}
                                className='text-white w-full p-4 bg-transparent rounded-2xl outline-none'
                                style={{
                                    border: '2px solid #3f3f46',
                                    backgroundImage: 'none',
                                    appearance: 'none',
                                    WebkitAppearance: 'none',
                                    MozAppearance: 'none'
                                }}
                            >
                                <option value='funding'>Funding</option>
                                <option value='trading'>Trading</option>
                            </select>
                        </div>
                        <div className='flex flex-col mt-5 justify-center'>
                            <button
                                className='py-3 px-3 text-white rounded-xl hover:bg-[#787f91] border-2 border-solid border-[#3f3f46] hover:border-[#787f91] duration-300'
                                onClick={() => {
                                    const temp = fromWallet;
                                    setFromWallet(toWallet);
                                    setToWallet(temp);
                                    updateCurrencyBalance(toWallet);
                                }}
                            >
                                <svg stroke="currentColor" fill="white" strokeWidth="0" viewBox="0 0 24 24" className="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.924 10.383a1 1 0 0 0-.217-1.09l-5-5-1.414 1.414L16.586 9H4v2h15a1 1 0 0 0 .924-.617zM4.076 13.617a1 1 0 0 0 .217 1.09l5 5 1.414-1.414L7.414 15H20v-2H5a.999.999 0 0 0-.924.617z"></path>
                                </svg>
                            </button>
                        </div>
                        <div className='flex flex-col items-start w-full'>
                            <p className='text-white text-sm font-medium w-full'>Transfer to</p>
                            <select
                                disabled
                                value={toWallet}
                                onChange={(e) => setToWallet(e.target.value)}
                                className='text-white w-full p-4 bg-transparent rounded-2xl outline-none'
                                style={{
                                    border: '2px solid #3f3f46',
                                    backgroundImage: 'none',
                                    appearance: 'none',
                                    WebkitAppearance: 'none',
                                    MozAppearance: 'none'
                                }}
                            >
                                <option value='trading'>Trading</option>
                                <option value='funding'>Funding</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <div className='flex justify-between items-center'>
                            <p className='text-white text-sm font-medium'>Amount</p>
                        </div>
                        <div>
                            <div className='relative flex items-center'>
                                <input
                                    type='number'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className='text-white w-full px-4 py-3 bg-transparent rounded-2xl outline-none pr-16'
                                    style={{
                                        border: '2px solid #3f3f46',
                                        backgroundImage: 'none',
                                        appearance: 'textfield',
                                        MozAppearance: 'textfield',
                                        WebkitAppearance: 'none'
                                    }}
                                />
                                <button
                                    className='absolute right-4 text-sm font-bold text-[#01bc8d]'
                                    onClick={() => setAmount(getBalance(currency.value, fromWallet))}
                                >
                                    MAX
                                </button>
                            </div>
                        </div>
                    </div>
                    {error && <p className="text-[#ea3941] text-center">{error}</p>}
                    {success && <p className="text-[#01bc8d] text-center">{success}</p>}
                    <button
                        onClick={handleTransfer}
                        className="bg-[#01bc8d33] w-full rounded-2xl p-4 text-base font-bold duration-500 text-[#01bc8d]"
                        disabled={buttonDisabled}
                    >
                        Transfer
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Transfer;
