import React, { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const WalletContext = createContext();
const currencies = ['btc', 'eth', 'usdc', 'ltc', 'trx', 'busd', 'doge', 'bnb', 'xrp']; // Убрали 'usdt'

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [prices, setPrices] = useState({});
    const [btcPrice, setBtcPrice] = useState(null);
    const [totalBalanceUsd, setTotalBalanceUsd] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const responses = await Promise.all(
                    currencies.map(currency => 
                        fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${currency.toUpperCase()}USDT`)
                    )
                );
                const data = await Promise.all(responses.map(response => response.json()));
                const prices = data.reduce((acc, item, index) => {
                    acc[currencies[index]] = parseFloat(item.price);
                    return acc;
                }, {});
                setPrices(prices);
            } catch (error) {
                console.error('Failed to fetch prices:', error);
            }
        };

        fetchPrices();
        const interval = setInterval(fetchPrices, 60000); // Обновление каждую минуту

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchBtcPrice = async () => {
            try {
                const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT');
                const data = await response.json();
                setBtcPrice(parseFloat(data.price));
            } catch (error) {
                console.error('Failed to fetch BTC price:', error);
            }
        };

        fetchBtcPrice();
        const interval = setInterval(fetchBtcPrice, 60000); // Обновление каждую минуту

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                }
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (Object.keys(prices).length > 0 && btcPrice !== null && userData) {
            const totalUsd = currencies.reduce((total, currency) => {
                const fundingBalance = userData.wallets.funding[currency] || 0;
                const tradingBalance = userData.wallets.trading[currency] || 0;
                const currencyBalance = fundingBalance + tradingBalance;
                
                // Специальная обработка для USDT
                const currencyValueUsd = currency === 'usdt' ? currencyBalance : currencyBalance * (prices[currency] || 0);
                
                return total + currencyValueUsd;
            }, 0);

            // Добавляем USDT в расчеты отдельно
            const usdtBalance = (userData.wallets.funding.usdt || 0) + (userData.wallets.trading.usdt || 0);
            setTotalBalanceUsd(totalUsd + usdtBalance);
            setLoading(false);
        }
    }, [prices, btcPrice, userData]);

    return (
        <WalletContext.Provider value={{ user, userData, prices, btcPrice, totalBalanceUsd, loading }}>
            {children}
        </WalletContext.Provider>
    );
};
