import React from 'react';
import './animations.css'; // Import the CSS file

const steps = [
  {
    title: 'Register',
    description: 'Registering on our exchange is quick and easy! Click Sign Up button, and follow the instructions.',
    icon: '/images/step1.webp',
  },
  {
    title: 'Topup Balance',
    description: 'Easily topup balance on our exchange by logging into your account, selecting deposit asset and make deposit.',
    icon: '/images/step2.webp',
  },
  {
    title: 'Start Trading',
    description: 'Embark on your thrilling crypto trading journey today and gain access to a wide range of digital currencies and trading tools.',
    icon: '/images/step3.webp',
  },
  {
    title: 'Earn Money',
    description: 'Take part in our promotions campaigns, unlock the potential of your investments and start earning money today.',
    icon: '/images/step4.webp',
  },
];

const HowItWorks = () => {
  return (
    <section className="bg-[#131315] text-white py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-center mb-6">How It Works</h2>
        <p className="text-center text-gray-400 mb-12">Buy and trade all your favorite tokens anytime and anywhere.</p>
        <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
          {steps.map((step, index) => (
            <div key={index} className={`flex flex-col items-center text-center animate-bottom animate-delay-${index + 1}`}>
              <img className="h-18 w-18 mb-4" src={step.icon} alt={step.title} />
              <h3 className="text-lg font-semibold mb-2">STEP {index + 1}</h3>
              <h4 className="text-xl font-bold mb-2">{step.title}</h4>
              <p className="text-gray-400">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
