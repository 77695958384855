import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Layout from './components/Layout';
import { WalletProvider } from './components/WalletContext';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component

import Home from './components/Home';
import BuyBlock from './components/home/BuyBlock';
import Dashboard from './components/Dashboard';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Assets from './components/Assets';
import Security from './components/Security';
import Password from './components/Password';
// import Email from './components/Email';
import Referral from './components/Referral';
import KYC from './components/KYC';
// import Orders from './components/Orders';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import Transfer from './components/Transfer';
import BuyCrypto from './components/BuyCrypto';
import FAQ from './components/FAQ';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Footer from './components/Footer';
// import TwoFA from './components/TwoFA';
import ReplainWidget from './components/ReplainWidget';

const App = () => {
  return (
    <WalletProvider>
      <Router>
        <AppContent />
      </Router>
    </WalletProvider>
  );
};

const AppContent = () => {
  const location = useLocation();
  // const isHome = location.pathname === '/';
  const isSignUp = location.pathname === '/signup';
  const isLogin = location.pathname === '/login';
  // const isFAQ = location.pathname === '/faq';
  // const isTerms = location.pathname === '/terms';
  const isTrade = location.pathname === '/trade';
  // const isPrivacy = location.pathname === '/privacy';
  // eslint-disable-next-line
  // const isDashboard = location.pathname === '/dashboard';
  // const isAccount = location.pathname.includes('/account');

  return (
    <div className="flex flex-col min-h-screen">
      <header>
        <Navbar />
        <ReplainWidget />
      </header>
      <main className="flex-grow">
        <Layout>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/buy-crypto' element={<BuyBlock />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/account' element={<Dashboard />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/account/assets" element={<Assets />} />
              <Route path="/account/assets/deposit" element={<Deposit />} />
              <Route path="/account/assets/buy-crypto" element={<BuyCrypto />} />
              <Route path="/account/assets/withdraw" element={<Withdraw />} />
              <Route path="/account/assets/transfer" element={<Transfer />} />
              <Route path="/account/security" element={<Security />} />
              <Route path="/account/security/password" element={<Password />} />
              <Route path="/account/kyc" element={<KYC />} />
              <Route path="/account/referral" element={<Referral />} />
            </Route>
          </Routes>
        </Layout>
      </main>
      <footer>
        {!isSignUp && !isLogin && !isTrade && <Footer />}
      </footer>
    </div>
  );
};

export default App;
