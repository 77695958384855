import React from 'react';
import { Link } from 'react-router-dom';

const KYCBar = () => {
  return (
    <div className="bg-[#131315] mb-4 block p-6 shadow-md text-white sm:flex rounded-2xl items-center" style={{ border: '2px solid #17181b' }}>
      <div className="hidden flex-shrink-0 mr-4 md:block">
      <div className='bg-[#01bc8d1a] p-3 rounded-xl flex items-center justify-center'>
      <svg stroke="#01bc8d" fill="#01bc8d" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-4xl text-primary" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 8v-2a2 2 0 0 1 2 -2h2"></path><path d="M4 16v2a2 2 0 0 0 2 2h2"></path><path d="M16 4h2a2 2 0 0 1 2 2v2"></path><path d="M16 20h2a2 2 0 0 0 2 -2v-2"></path><path d="M9 10l.01 0"></path><path d="M15 10l.01 0"></path><path d="M9.5 15a3.5 3.5 0 0 0 5 0"></path></svg>
      </div>
      </div>
      <div>
        <h2 className="text-sm font-mediun text-[#787f91]">Help us secure your account</h2>
        <p className="font-bold">Complete verification for enhanced security</p>
      </div>
      <div className="ml-auto mt-3 flex justify-end sm:mt-0 sm:justify-start">
        <Link to='/account/kyc' className="bg-[#01bc8d20] text-sm text-[#01bc8d] font-bold py-2 px-4 rounded-[1rem] hover:bg-[#20342e] transition-colors duration-300">Verify now</Link>
      </div>
    </div>
  );
}

export default KYCBar;
