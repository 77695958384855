import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import './animations.css'; // Import the CSS file

const features = [
    {
        title: 'Portfolio Manager',
        description: 'Buy and sell popular digital currencies, keep track of them in the one place.',
        icon: '/images/portfolio.svg'
    },
    {
        title: 'Vault protection',
        description: 'For added security, store your funds in a vault with time delayed withdrawals.',
        icon: '/images/lock.svg'
    },
    {
        title: 'Phone protection',
        description: 'Use 2FA protection for your assets and stay safe.',
        icon: '/images/phone.svg'
    }
];

const Features = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });

        return () => unsubscribe();
    }, []);


    return (
        <section className="bg-[#131315] text-white pt-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center lg:space-x-8">
                <div className="grid grid-cols">
                    {features.slice(0, 2).map((feature, index) => (
                        <div key={index} className={`bg-[#23262f] p-6 rounded-lg shadow-lg h-72 w-72 flex flex-col items-center justify-center mb-8 border border-gray-700 animate-bottom animate-delay-${index + 1}`}>
                            <img src={feature.icon} alt={feature.title} className="h-24 w-24 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                            <p className="text-gray-400 text-center">{feature.description}</p>
                        </div>
                    ))}
                </div>
                <div className="grid grid-cols-1">
                    {features.slice(2, 3).map((feature, index) => (
                        <div key={index} className={`bg-[#23262f] p-6 rounded-lg shadow-lg h-72 w-72 flex flex-col items-center justify-center mb-8 lg:mb-0 border border-gray-700 animate-top animate-delay-${index + 3}`}>
                            <img src={feature.icon} alt={feature.title} className="h-24 w-24 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                            <p className="text-gray-400 text-center">{feature.description}</p>
                        </div>
                    ))}
                </div>
                <div className="hidden lg:flex flex-col items-center lg:items-start justify-between animate-left animate-delay-4">
                    <div className="text-center lg:text-left mt-8 lg:mt-0 lg:ml-8">
                        <h2 className="text-3xl font-extrabold">The Most Trusted Cryptocurrency Platform.</h2>
                        <p className="text-gray-400 mt-4">
                            {process.env.REACT_APP_NAME_LOW} has a variety of features that make it the best place to start trading.
                        </p>
                        {!isAuthenticated && <Link to="/login" className="inline-block px-6 py-3 mt-2 bg-[#01bc8d20] text-[#01bc8d] font-semibold rounded-full shadow-md hover:bg-[#20342e]">
                            Let’s Trade Now</Link>}
                        {isAuthenticated && <Link to="/account/assets/buy-crypto" className="inline-block px-6 py-3 mt-2 bg-[#01bc8d20] text-[#01bc8d] font-semibold rounded-full shadow-md hover:bg-[#20342e]">
                            Let’s Trade Now</Link>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Features;
