import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';

const ReplainWidget = () => {
    const [replainId, setReplainId] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        const fetchReplainId = async () => {
            const replainDoc = await getDoc(doc(db, 'settings', 'replain'));
            if (replainDoc.exists()) {
                const replainData = replainDoc.data();
                setReplainId(replainData.replainId);
            }
        };

        fetchReplainId();
    }, []);

    useEffect(() => {
        let handleClickOutside;

        if (replainId && isAuthenticated && !window.replainLoaded) {
            window.replainSettings = { id: replainId };
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://widget.replain.cc/dist/client.js';
            document.body.appendChild(script);

            script.onload = () => {
                const intervalId = setInterval(() => {
                    const iframe = document.querySelector('iframe');
                    if (iframe && iframe.contentDocument) {
                        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                        if (iframeDocument && iframeDocument.readyState === 'complete') {
                            const style = document.createElement('style');
                            style.innerHTML = `
                                .btn-img {
                                    transition: transform 0.3s !important;
                                }
                                .btn-img:hover {
                                    transform: scale(1.1) !important;
                                }
                            `;
                            iframeDocument.head.appendChild(style);

                            // Function to close the chat
                            const closeChat = () => {
                                const closeButton = iframeDocument.querySelector('.hide-chat');
                                if (closeButton) {
                                    closeButton.click();
                                }
                            };

                            // Event listener to close the chat on clicking outside
                            handleClickOutside = (event) => {
                                if (
                                    iframe &&
                                    iframe.contentDocument &&
                                    !iframe.contains(event.target) &&
                                    !iframe.contentDocument.contains(event.target)
                                ) {
                                    closeChat();
                                }
                            };

                            document.addEventListener('click', handleClickOutside);

                            clearInterval(intervalId);
                        }
                    }
                }, 1000);
            };

            window.replainLoaded = true;
        }

        return () => {
            if (handleClickOutside) {
                document.removeEventListener('click', handleClickOutside);
            }
        };
    }, [replainId, isAuthenticated]);

    return null;
};

export default ReplainWidget;
