import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

const Security = () => {
    const [lastPasswordChange, setLastPasswordChange] = useState('');
    const [recentLogins, setRecentLogins] = useState([]);

    useEffect(() => {
        const fetchData = async (userId) => {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setLastPasswordChange(userData.lastPasswordChange || userData.signuptime);
                setRecentLogins(userData.recentLogins || []);
            } else {
                console.log('No such document!');
            }
        };

        onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchData(user.uid);
            }
        });
    }, []);

    return (
        <div className='p-2 md:p-14'>
            <div className='flex items-center justify-between'>
                <div>
                    <h1 className='pb-0 md:pb-2 text-2xl md:text-3xl font-bold text-white'>Security Settings</h1>
                    <p className="text-sm font-mediun text-[#787f91]">
                        Improve security of your account
                    </p></div>
                <div className="ml-auto mt-3 flex justify-end sm:mt-0 sm:justify-start">
                    <p className="bg-[#ea394120] text-sm text-[#ea3941] font-bold py-2 md:py-2 px-4 rounded-[1rem]">Security: Low</p>
                </div>
            </div>

            <h3 className='text-base font-semibold text-white pt-6 pb-2'>Advanced Settings</h3>

            <div className="bg-[#131315] mb-4 block p-6 shadow-md text-white sm:flex rounded-2xl items-center hover:bg-[#17181b] transition-colors duration-300" style={{ border: '2px solid #17181b' }}>
                <div>
                    <p className="font-bold">Password</p>
                    <p className="text-sm font-mediun text-[#787f91]">
                        Last Change: {lastPasswordChange}</p>
                </div>
                <div className="ml-auto mt-3 flex justify-end sm:mt-0 sm:justify-start">
                    <Link to='/account/security/password' className="bg-[#01bc8d20] text-sm text-[#01bc8d] font-bold py-2 md:py-4 px-4 rounded-[1rem] hover:bg-[#20342e] transition-colors duration-300">Change</Link>
                </div>
            </div>
            <h3 className='text-base font-semibold text-white pt-6 pb-2'>Recents Logins</h3>

            <div className="block bg-[#131315] mb-4 py-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181b' }}>
                <div className="flex flex-col">
                    <div className="-m-1.5 p-0 md:p-6 xl:p-0 overflow-x-auto">
                        <div className="p-1.5 min-w-full inline-block align-middle">
                            <div className="overflow-hidden w-full">
                                <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="w-64 pb-5 pl-6 text-left text-sm font-bold">Login IP</th>
                                            <th scope="col" className="w-64 pb-5 text-left text-sm font-bold hidden sm:table-cell">Login Device</th>
                                            <th scope="col" className="w-64 pb-5 text-left text-sm font-bold">Time</th>
                                            <th scope="col" className="w-64 pb-5 text-left text-sm font-bold">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recentLogins.map((login, index) => (
                                            <tr key={index} className="hover:bg-[#17181b] border-t border-[#17181b] h-16 transition duration-500 w-full">
                                                <td className="pl-6 sm:pl-6 whitespace-nowrap">{login.ip}</td>
                                                <td className="text-left whitespace-nowrap text-sm font-medium hidden sm:table-cell">{login.device}</td>
                                                <td className="text-left whitespace-nowrap text-sm font-medium">{login.time}</td>
                                                <td className="text-left whitespace-nowrap text-sm font-medium">{login.status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Security;

// eslint-disable-next-line
{/* <h3 className='text-base font-semibold text-white pt-6 pb-2'>Two-Factor Authentication (2FA)</h3>
            <div className="hover:bg-[#17181b] bg-[#131315] mb-4 block p-6 shadow-md text-white sm:flex rounded-2xl items-center transition-colors duration-300" style={{ border: '2px solid #17181b' }}>
                <div>
                    <p className="font-bold">Google Authenticator</p>
                    <p className="text-sm font-mediun text-[#787f91]">
                        Used to verify account logins, withdrawals, API creation, etc.</p>
                </div>
                <div className="ml-auto mt-3 flex justify-end sm:mt-0 sm:justify-start">
                    <Link to='/account/kyc' className="bg-[#01bc8d20] text-sm text-[#01bc8d] font-bold py-2 md:py-4 px-4 rounded-[1rem] hover:bg-[#20342e] transition-colors duration-300">Bind</Link>
                </div>
            </div> */}
// eslint-disable-next-line
{/*
            <div className="hover:bg-[#17181b] bg-[#131315] mb-4 block p-6 shadow-md text-white sm:flex rounded-2xl items-center transition-colors duration-300" style={{ border: '2px solid #17181b' }}>
                <div>
                    <p className="font-bold">Email</p>
                    <p className="text-sm font-mediun text-[#787f91]">Used to verify withdrawals, API creation, etc.</p>
                </div>
                <div className="ml-auto mt-3 flex justify-end sm:mt-0 sm:justify-start">
                    <Link to='/account/security/email' className="bg-[#01bc8d20] text-sm text-[#01bc8d] font-bold p-2 md:py-4 px-4 rounded-[1rem] hover:bg-[#20342e] transition-colors duration-300">Change</Link>
                </div>
            </div> */}