import React from 'react';
import { Link } from 'react-router-dom';

const ReferralBar = ({ userData }) => {
  if (!userData) {
    return null;
  }

  return (
    <div className="block bg-[#131315] p-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181b' }}>
      <div className='flex justify-between items-center'>
        <h2 className="text-sm font-medium text-[#787f91]">Invite Friends</h2>
        <Link to="/account/referral" className="bg-[#01bc8d20] text-sm text-[#01bc8d] font-bold py-2 px-4 rounded-[1rem] hover:bg-[#20342e] hover:border-[#2b4f41] transition-colors duration-300">Referral System</Link>
      </div>
      <div className='block mt-4'>
        <div className='bg-[#17181c] rounded-2xl block text-left p-6 mb-4 sm:mb-4 w-auto' style={{ border: '2px solid #17181b' }}>
          <p className='text-[#787f91] mb-2 font-normal text-base'>Your code</p>
          <p className='font-bold text-base md:text-2xl'>{userData.refcode}</p>
        </div>
        <div className='bg-[#17181c] rounded-2xl block text-left p-6 w-auto' style={{ border: '2px solid #17181b' }}>
          <p className='text-[#787f91] mb-2 font-normal text-base'>Your link</p>
          <p className='font-bold text-base md:text-2xl'>{process.env.REACT_APP_LINK_SHORT}rf/{userData.refcode}</p>
        </div>
      </div>
    </div>
  );
}

export default ReferralBar;
