import React from 'react';
import { Link } from 'react-router-dom';

const services = [
    {
        name: 'MoonPay',
        description: 'MoonPay offers a fast and simple way to buy and sell cryptocurrencies. Buy crypto with credit card, bank transfers or Apple Pay today.',
        imgSrc: '/images/moonpay.svg',
        link: 'https://moonpay.com/'
    },
    {
        name: 'Transak',
        description: 'Transak is a leading Web3 onboarding infrastructure provider. Its API-driven solutions enable web3 platforms to onboard users to 130+ crypto assets from 150+ countries, abstracting away the complexity of user KYC, risk monitoring & compliance, payment methods and customer support.',
        imgSrc: '/images/transak.svg',
        link: 'https://global.transak.com/'
    },
    {
        name: 'Simplex',
        description: 'Simplex by Nuvei is empowering the crypto industry with a full fiat infrastructure. We process crypto-to-credit card payments with a 100% guarantee – in case of a fraud chargeback, the merchant gets paid by us.',
        imgSrc: '/images/simplex.svg',
        link: 'https://www.simplex.com/buy-crypto'
    },
    {
        name: 'Mercuryo',
        description: 'A custodial wallet with built-in crypto on-ramp functionality. Designed to manage your crypto just like you do with fiat: buy, sell, hold, and spend a wide range of coins.',
        imgSrc: '/images/mercuryo.svg',
        link: 'https://exchange.mercuryo.io/'
    },
    {
        name: 'CoinGate',
        description: 'CoinGate is a Lithuanian-based fintech company founded in 2014. The payment gateway offers cryptocurrency payment processing services for businesses of any sizes.',
        imgSrc: '/images/coingate.svg',
        link: 'https://coingate.com/buy-and-sell'
    },
    {
        name: 'Banxa',
        description: 'Banxa is the leading global Web3 on and off-ramp solution Crypto Buy. Our mission is to accelerate the world to Web3 and beyond.',
        imgSrc: '/images/banxa.svg',
        link: 'https://openocean.banxa.com/'
    },
    {
        name: 'Bitpay',
        description: 'Buy crypto with a credit card, debit card, Apple Pay or Google Pay. Delivered quickly to any wallet, no hidden fees or third-party custody. Buy online or in the BitPay app.',
        imgSrc: '/images/bitpay.svg',
        link: 'https://bitpay.com/buy-crypto/'
    },
    {
        name: 'Changelly',
        description: 'Changelly is an ecosystem of products that allows you to exchange, buy, trade, and sell cryptocurrencies and also earn free crypto with our affiliate program.',
        imgSrc: '/images/changelly.svg',
        link: 'https://changelly.com/buy'
    },
    {
        name: 'Phemex',
        description: 'Phemex operates as a crypto derivatives trading platform. Phemex is a professional and trustworthy global cryptocurrency derivatives exchange. We offer Bitcoin, Ethereum, Ripple, and Chainlink perpetual contracts, with up to 100x leverage.',
        imgSrc: '/images/phemex.svg',
        link: 'https://phemex.com/fiat/buy-crypto/place-order?side=buy'
    },
    {
        name: 'Ramp',
        description: 'Ramp is a global financial technology company building solutions that connect the crypto economy with today’s financial infrastructure.',
        imgSrc: '/images/ramp.svg',
        link: 'https://ramp.network/buy'
    }
];

const ServiceCard = ({ service }) => (
    <div className='bg-[#17181c] rounded-2xl p-6 w-full  flex flex-col justify-between gap-y-4'>
        <div>
            <div className='bg-white mb-6 rounded-2xl p-4 flex justify-center items-center'>
                <img className='h-[64px]' src={service.imgSrc} alt={service.name} />
            </div>
            <p className='font-medium text-sm text-white'>
                {service.description}
            </p>
        </div>
            <Link target='_blank' to={service.link} className='rounded-2xl transition-colors duration-500 font-semibold bg-[#787f9133] p-6 text-center text-white hover:bg-[#787f9170]'>Buy Crypto on {service.name}</Link>
    </div>
);

const BuyCrypto = () => (
<div className='p-2 md:p-14' style={{width: '100%)'}}>
        <div className='flex items-center justify-between'>
            <div>
                <h1 className='pb-0 md:pb-2 text-2xl md:text-3xl font-bold text-white'>Buy Crypto</h1>
                <p className="text-sm pb-2 font-mediun text-[#787f91]">
                    On this page you can buy crypto with several providers
                </p></div>
        </div>
        <div className="block bg-[#131315] mb-4 p-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181b' }}>
            <div className='grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {services.map((service, index) => (
                    <ServiceCard key={index} service={service} />
                ))}
            </div>
        </div>
    </div>
);

export default BuyCrypto;
