import React from 'react';

const faqData = [
    {
        question: "How to log in to your account?",
        answer: "To log in to your account, click on the 'Login' button on the top right corner of the homepage. Enter your email and password, then click 'Submit'."
    },
    {
        question: "How to register an account?",
        answer: "To register an account, click on the 'Sign Up' button on the top right corner of the homepage. Fill in the required fields with your personal information, and follow the instructions to complete the registration process."
    },
    {
        question: "What should I do if I haven't received the confirmation letter?",
        answer: "If you haven't received the confirmation letter, check your spam/junk folder. If you still can't find it, try resending the confirmation email or contact customer support for assistance."
    },
    {
        question: "How can I change my personal data if I do not have access to my account (forgot my password) and to my mail?",
        answer: "If you do not have access to your account and email, contact customer support for verification and assistance in changing your personal data."
    },
    {
        question: "What do I do if I need to make changes to the data entered during the verification process?",
        answer: "To make changes to your verification data, contact customer support with the necessary documents and information for verification."
    }
];

const faqData2 = [
    {
        question: "How to make a currency withdrawal on the exchange?",
        answer: "To withdraw currency, log in to your account, go to the 'Withdraw' section, select the currency, enter the withdrawal address, and follow the instructions to complete the process."
    },
    {
        question: "On what terms are the requests for withdrawal being processed?",
        answer: "Withdrawal requests are processed based on the exchange's policies, which may include transaction fees, processing times, and minimum withdrawal amounts."
    },
    {
        question: "How do I cancel a withdrawal request?",
        answer: "To cancel a withdrawal request, go to the 'Withdraw' section of your account, find the pending request, and click 'Cancel'. If the request is already processed, it cannot be canceled."
    },
    {
        question: "How do I replenish my balance?",
        answer: "To replenish your balance, log in to your account, go to the 'Deposit' section, select the currency, and follow the instructions to complete the deposit."
    }
];

const faqData3 = [
    {
        question: `What is ${process.env.REACT_APP_NAME}.COM Referral Program?`,
        answer: "The Referral Program allows you to earn rewards by referring new users to the platform. You can share your referral link and earn a commission from their trading activities."
    },
    {
        question: "How does the Referral Program work?",
        answer: "The Referral Program works by providing you with a unique referral link to share. When someone registers using your link and starts trading, you earn a commission based on their trading volume."
    },
    {
        question: "How to become a member of Referral Program?",
        answer: "To become a member of the Referral Program, log in to your account, go to the 'Referral' section, and find your unique referral link to start sharing."
    }
];

const faqData4 = [
    {
        question: "How to enable the two-factor authentication?",
        answer: "To enable two-factor authentication, log in to your account, go to the 'Security' section, and follow the instructions to set up 2FA using an authenticator app."
    },
    {
        question: "What if I lost the code / key to two-factor authentication?",
        answer: "If you lost the 2FA code/key, contact customer support for assistance in disabling 2FA temporarily to regain access to your account."
    },
    {
        question: `Is it safe to use ${process.env.REACT_APP_NAME}.COM exchange?`,
        answer: `Yes, ${process.env.REACT_APP_NAME}.COM uses advanced security measures such as two-factor authentication, encryption, and cold storage to protect your funds and data.`
    },
    {
        question: "What is two-factor authentication?",
        answer: "Two-factor authentication (2FA) is an additional security layer that requires not only a password and username but also something that only the user has on them, i.e., a piece of information only they should know or have immediately to hand – such as a physical token."
    },
    {
        question: "What is KYC and why is it needed?",
        answer: "KYC (Know Your Customer) is a process of verifying the identity of clients. It is required to comply with legal regulations and to ensure the security of the platform."
    }
];

const faqData5 = [
    {
        question: "I purchased currency several days ago, but it still hasn't been received. What do I do?",
        answer: "If you haven't received your purchased currency, check the transaction status on the blockchain explorer. If the transaction is confirmed but not reflected in your account, contact customer support."
    },
    {
        question: "I can not cancel the Order / Tokens were not credited to the account",
        answer: "If you cannot cancel the order or if tokens were not credited, contact customer support with the transaction details for assistance."
    },
    {
        question: "Can I cancel an Order?",
        answer: "Yes, you can cancel an order as long as it has not been processed. Go to the 'Orders' section of your account, find the order, and click 'Cancel'."
    },
    {
        question: "Where can I see the history of my orders?",
        answer: "You can view the history of your orders in the 'Orders' section of your account. It provides details of all your past transactions."
    },
    {
        question: "When will my Order be processed?",
        answer: "Order processing times depend on various factors including network congestion and verification. Generally, orders are processed within a few minutes to a few hours."
    },
    {
        question: "I placed an order, but for some reason, it is not accepted, although I can see cheaper Buy Orders being already processed. Why?",
        answer: "If your order is not accepted, it could be due to price fluctuations or order matching rules. Review your order details or contact customer support for clarification."
    },
    {
        question: "Where can I see Buy Orders?",
        answer: "You can view Buy Orders in the 'Orders' section of your account, which lists all active and past buy orders."
    },
    {
        question: "How to create an Order?",
        answer: "To create an order, log in to your account, go to the 'Trade' section, select the currency pair, enter the order details, and click 'Submit'."
    },
    {
        question: "Is there any transaction limit? What is the maximum / minimum amount of the transaction?",
        answer: "Transaction limits vary depending on the currency and verification level. Check the 'Limits' section of your account for specific details."
    }
];

function FAQBlock() {
    return (
        <div className='p-2 md:p-14'>
            <div className='flex items-center justify-between pb-6'>
                <div>
                    <h1 className='pb-0 md:pb-2 text-2xl md:text-3xl font-bold text-white'>Individual Identity Verification</h1>
                    <p className="text-sm font-mediun text-[#787f91]">
                        It only takes 3-5 minutes to verify your account
                    </p></div>
            </div>
            <div className='bg-[#131315] rounded-2xl p-5' style={{ border: '2px solid #17181b' }}>
                <div className="text-white">
                    {faqData.map((faq, index) => (
                        <div key={index} className="mb-2">
                            <div className="bg-[#17181b] p-3 rounded-lg text-lg transition-colors ">
                                {faq.question}
                            </div>
                            <div className="p-3 text-[#787f91]">
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <label className='block font-semibold text-lg mt-4 mb-1 text-white'>Deposit / Withdrawal</label>
            <div className='bg-[#131315] rounded-2xl p-5' style={{ border: '2px solid #17181b' }}>
                <div className="text-white">
                    {faqData2.map((faq, index) => (
                        <div key={index} className="mb-2">
                            <div className="bg-[#17181b] p-3 rounded-lg text-lg transition-colors ">
                                {faq.question}
                            </div>
                            <div className="p-3 text-[#787f91]">
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <label className='block font-semibold text-lg mt-4 mb-1 text-white'>Referral Program</label>
            <div className='bg-[#131315] rounded-2xl p-5' style={{ border: '2px solid #17181b' }}>
                <div className="text-white">
                    {faqData3.map((faq, index) => (
                        <div key={index} className="mb-2">
                            <div className="bg-[#17181b] p-3 rounded-lg text-lg transition-colors ">
                                {faq.question}
                            </div>
                            <div className="p-3 text-[#787f91]">
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <label className='block font-semibold text-lg mt-4 mb-1 text-white'>Secure your funds</label>
            <div className='bg-[#131315] rounded-2xl p-5' style={{ border: '2px solid #17181b' }}>
                <div className="text-white">
                    {faqData4.map((faq, index) => (
                        <div key={index} className="mb-2">
                            <div className="bg-[#17181b] p-3 rounded-lg text-lg transition-colors ">
                                {faq.question}
                            </div>
                            <div className="p-3 text-[#787f91]">
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <label className='block font-semibold text-lg mt-4 mb-1 text-white'>Currency operations</label>
            <div className='bg-[#131315] rounded-2xl p-5' style={{ border: '2px solid #17181b' }}>
                <div className="text-white">
                    {faqData5.map((faq, index) => (
                        <div key={index} className="mb-2">
                            <div className="bg-[#17181b] p-3 rounded-lg text-lg transition-colors ">
                                {faq.question}
                            </div>
                            <div className="p-3 text-[#787f91]">
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FAQBlock;
