import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import './animations.css'; // Import the CSS file

const HeroSection = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });

        return () => unsubscribe();
    }, []);

    return (
        <section className="bg-[#131315] text-white pt-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center lg:space-x-12">
                <div className="lg:w-1/2 text-center lg:text-left lg:mb-0 animate-left animate-delay-1">
                    <h1 className="text-4xl font-extrabold mb-4">A trusted and secure cryptocurrency exchange.</h1>
                    <p className="text-gray-400 mb-8">
                        Buy, sell, and grow your crypto with, the platform dedicated to every trader at every level.
                    </p>

                    {!isAuthenticated && <Link to="/login" className="inline-block px-6 py-3 bg-[#01bc8d20] text-[#01bc8d] font-semibold rounded-full shadow-md hover:bg-[#20342e]">
                        Trade Now</Link>}
                    {isAuthenticated && <Link to="/account/assets/buy-crypto" className="inline-block px-6 py-3 bg-[#01bc8d20] text-[#01bc8d] font-semibold rounded-full shadow-md hover:bg-[#20342e]">
                        Trade Now</Link>}
                </div>
                <div className="lg:w-1/2 flex justify-center lg:justify-end animate-right animate-delay-2">
                    <div className="relative hidden lg:block">
                        <img src="/images/mac.svg" alt="Cryptocurrency Exchange" className="w-full max-w-3xl" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
