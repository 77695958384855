import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import HeroSection from './home/HeroSection';
import Features from './home/Features';
import TrustedPlatform from './home/TrustedPlatform';
import HowItWorks from './home/HowItWorks';
import PromoBlock from './home/PromoBlock';

const Home = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return unsubscribe;
  }, []);

  return (
    <div className='p-0'>
      <HeroSection />
      <Features />
      <TrustedPlatform />
      <HowItWorks />
      {!isAuthenticated && <PromoBlock />}
    </div>
  );
};

export default Home;
