import React from 'react';

const Skeleton = () => (
  <div className="animate-pulse">
    <div className="flex items-center space-x-4">
      <div className="rounded-full bg-gray-700 h-10 w-10"></div>
      <div className="flex-1 space-y-4 py-1">
        <div className="h-4 bg-gray-700 rounded w-3/4"></div>
        <div className="h-4 bg-gray-700 rounded w-1/2"></div>
      </div>
    </div>
    <div className="flex items-center space-x-4 mt-4">
      <div className="h-4 bg-gray-700 rounded w-full"></div>
    </div>
  </div>
);

export default Skeleton;
