import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';
import { publicIpv4 } from 'public-ip';
import UAParser from 'ua-parser-js';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/dashboard');
            }
        });
        return unsubscribe;
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);

            const user = auth.currentUser;
            if (user) {
                const ip = await publicIpv4();
                const parser = new UAParser();
                const result = parser.getResult();
                const device = `${result.browser.name} ${result.browser.version} on ${result.os.name} ${result.os.version}`;
                const time = new Date().toLocaleString('en-GB');

                const userRef = doc(db, 'users', user.uid);
                await updateDoc(userRef, {
                    recentLogins: [{ ip, device, time, status: 'Online' }]
                });
            }

            navigate('/dashboard');
        } catch (error) {
            if (error.code === 'auth/invalid-credential' || error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
                setError('Wrong email or password');
            } else {
                setError('Failed to log in: ' + error.message);
            }
        }
    };

    return (
        <div>
            <div className='bg-[#17181b] w-full flex text-white p-3 items-center justify-center text-xs'>
                <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 24 24" className="text-primary text-lg mr-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C9.243 2 7 4.243 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9V7zm4 10.723V20h-2v-2.277a1.993 1.993 0 0 1 .567-3.677A2.001 2.001 0 0 1 14 16a1.99 1.99 0 0 1-1 1.723z"></path></svg>
                <p className='font-bold'>URL verification: <span className='font-medium'>{process.env.REACT_APP_LINK}</span></p>
            </div>
            <div className="p-2 md:p-14 flex items-center justify-center">
                <div className="w-full md:w-2/5 bg-[#17181b] py-8 px-10 rounded-2xl">
                    <h1 className="text-3xl font-bold mb-6 text-white">Log In</h1>
                    <form className="space-y-4" onSubmit={handleLogin}>
                        <div className='block justify-between items-center'>
                            <p className='text-white text-sm mb-1 font-bold w-2/5'>Email</p>
                            <input
                                type='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Enter your email'
                                className='text-white w-full px-4 py-3 bg-transparent rounded-2xl outline-none bg-[#27272a]'
                                style={{ border: '2px solid #27272a' }}
                            />
                        </div>
                        <div className='block justify-between items-center'>
                            <p className='text-white text-sm mb-1 font-bold w-2/5'>Password</p>
                            <input
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='Enter your password'
                                className='text-white w-full px-4 py-3 bg-transparent rounded-2xl outline-none bg-[#27272a]'
                                style={{ border: '2px solid #27272a' }}
                            />
                        </div>
                        {error && <p className='text-[#ea3941]'>{error}</p>}
                        <button type="submit" className="bg-[#01bc8d33] w-full rounded-2xl p-4 text-base font-bold duration-500 text-[#01bc8d] hover:bg-[#4ea984] hover:text-white">Log In</button>
                    </form>
                    <p className="mt-4 text-sm text-white">
                        Don't have an account? <Link to="/signup" className="text-[#01bc8d]">Sign up now</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Login;
