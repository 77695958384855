import React, { useState } from 'react';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';

const VoucherPopup = ({ isOpen, onClose, onVoucherApplied }) => {
  const [voucherCode, setVoucherCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleVoucherChange = (e) => {
    setVoucherCode(e.target.value);
  };

  const handleVoucherSubmit = async () => {
    setError('');
    setSuccess('');

    const user = auth.currentUser;
    if (!user) {
      setError('User not authenticated');
      return;
    }

    try {
      const voucherRef = doc(db, 'vouchers', voucherCode);
      const voucherDoc = await getDoc(voucherRef);

      if (!voucherDoc.exists()) {
        setError('Invalid voucher code');
        return;
      }

      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        setError('User data not found');
        return;
      }

      const userData = userDoc.data();
      const usedVouchers = userData.usedVouchers || [];

      if (usedVouchers.includes(voucherCode)) {
        setError('You already used this code');
        return;
      }

      const currentBTC = userData.wallets?.funding?.btc || 0;
      const voucherValue = voucherDoc.data().value || 0;
      const depositTime = new Date().toLocaleString('en-GB');

      await updateDoc(userRef, {
        usedVouchers: [...usedVouchers, voucherCode],
        'wallets.funding.btc': currentBTC + voucherValue,
        depositHistory: arrayUnion({
          coin: 'BTC',
          time: depositTime,
          amount: voucherValue,
          transactionHash: 'N/A',
          status: 'Completed'
        })
      });

      setSuccess(`Voucher successfully applied! ${voucherValue} BTC was credited to your balance`);
      // onVoucherApplied();
    } catch (error) {
      console.error('Failed to apply voucher:', error);
      setError('Failed to apply voucher');
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed p-2 inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="relative bg-[#131315] w-[450px] px-6 h-[350px] rounded-xl flex flex-col justify-center items-center" style={{ border: '2px solid #23262f' }}>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white text-xl font-bold focus:outline-none"
        >
          &times;
        </button>
        {success ? (
          <>
            <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 16 16" className="text-8xl text-primary" height="75" width="75" xmlns="http://www.w3.org/2000/svg"><path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path></svg>
            <p className='text-white mt-4 font-bold text-xl'>Completed</p>
            <p className="text-center text-white mt-1">{success}</p>
            <button className="hover:bg-[#243f36] bg-[#01bc8d33] w-1/3 mt-6 rounded-2xl p-4 text-base font-bold duration-500 text-[#01bc8d]" onClick={() => window.location.href = '/account/assets'}>Assets</button>
          </>
        ) : (
          <>
            <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 512 512" className="text-8xl text-primary h-[96px] w-[96px]" height="96" width="96" xmlns="http://www.w3.org/2000/svg"><path d="M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm256 32h160c17.7 0 32-14.3 32-32V320H288v160zm192-320h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40z"></path></svg>
            <p className="text-white my-4">Activate your voucher</p>
            <input
              name="voucher"
              value={voucherCode}
              onChange={handleVoucherChange}
              placeholder='Enter voucher code'
              className='w-full p-4 bg-transparent rounded-2xl outline-none text-white'
              style={{ border: '2px solid #3f3f46' }}
            />
            <button
              onClick={handleVoucherSubmit}
              className="hover:bg-[#243f36] bg-[#01bc8d33] w-full mt-6 rounded-2xl p-4 text-base font-bold duration-500 text-[#01bc8d]"
            >
              Submit
            </button>
            {error && <p className="text-[#ea3941] mt-2">{error}</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default VoucherPopup;
