// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDDcN5h7CooowPipnW6H0VjWBQ4aiWKdQQ",
  authDomain: "cex-db-test.firebaseapp.com",
  databaseURL: "https://cex-db-test-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cex-db-test",
  storageBucket: "cex-db-test",
  messagingSenderId: "578234316757",
  appId: "1:578234316757:web:a98ed9ef3b8863f588ef5c",
  measurementId: "G-7ZREEHQ9HM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Инициализация Firestore

export { auth, db };
