import React, { useState } from 'react';
import VoucherPopup from '../VoucherPopup';

const VoucherBar = () => {
  const [isVoucherPopupOpen, setIsVoucherPopupOpen] = useState(false);

  const toggleVoucherPopup = () => {
    setIsVoucherPopupOpen(!isVoucherPopupOpen);
  };

  const handleVoucherApplied = () => {
    setIsVoucherPopupOpen(false);
  };

  return (
    <>
      <div className="bg-[#54b99020] mb-4 flex py-3 px-6 shadow-md text-white lg:hidden rounded-2xl items-center" style={{ border: '2px solid #54b990' }}>
        <svg className='mr-4' stroke="currentColor" fill="#54b990" strokeWidth="0" viewBox="0 0 512 512" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm256 32h160c17.7 0 32-14.3 32-32V320H288v160zm192-320h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40z"></path></svg>
        <button className='text-[#54b990] font-bold' onClick={toggleVoucherPopup}>Voucher</button>
      </div>
      <VoucherPopup
        isOpen={isVoucherPopupOpen}
        onClose={toggleVoucherPopup}
        onVoucherApplied={handleVoucherApplied}
      />
    </>
  );
};

export default VoucherBar;
