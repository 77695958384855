import React, { useState, useEffect, useCallback } from 'react';
import { useWallet } from './WalletContext';
import { auth, db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import './Transfer.css';

const currencyOptions = [
    { value: 'btc', label: 'Bitcoin', icon: '/images/btc-logo.png', min: '0.0004700' },
    { value: 'eth', label: 'Ethereum', icon: '/images/eth-logo.png', min: '0.0095' },
    { value: 'ltc', label: 'Litecoin', icon: '/images/ltc-logo.png', min: '0.40' },
    { value: 'usdc', label: 'USD Coin', icon: '/images/usdc-logo.png', min: '25' },
    { value: 'usdt', label: 'Tether', icon: '/images/usdt-logo.png', min: '25' },
    { value: 'bnb', label: 'Binance Coin', icon: '/images/bnb-logo.png', min: '0.05512' },
    { value: 'doge', label: 'Dogecoin', icon: '/images/doge-logo.png', min: '270.0567' },
    { value: 'trx', label: 'Tron', icon: '/images/trx-logo.png', min: '220.01423' },
    // { value: 'xrp', label: 'Ripple', icon: '/images/xrp-logo.png', min: '' },
];

const networkOptions = {
    btc: [{ value: 'btc', label: 'Bitcoin', block: '3' }],
    eth: [{ value: 'evm', label: 'ERC20', block: '10' }],
    ltc: [{ value: 'ltc', label: 'Litecoin', block: '3' }],
    bnb: [{ value: 'evm', label: 'BEP20', block: '3' }],
    doge: [{ value: 'doge', label: 'Dogecoin', block: '35' }],
    trx: [{ value: 'trx', label: 'TRC20', block: '3' }],
    // xrp: [{ value: 'xrp', label: 'XRP' }],
    usdt: [
        { value: 'evm', label: 'ERC20', block: '10' },
        { value: 'evm', label: 'BEP20', block: '3' },
        { value: 'evm', label: 'Polygon', block: '200' },
        { value: 'trx', label: 'TRC20', block: '3' }
    ],
    usdc: [
        { value: 'evm', label: 'ERC20', block: '10' },
        { value: 'evm', label: 'BEP20', block: '3' },
        { value: 'evm', label: 'Polygon', block: '200' },
        { value: 'trx', label: 'TRC20', block: '3' }
    ],
};

// Custom SingleValue component to display the icon, label, balance, and USD value
const SingleValue = (props) => {
    // Приведение значений к числу с дефолтными значениями
    const balance = Number(props.data.balance) || 0;
    const usdPrice = Number(props.data.usdPrice) || 0;

    return (
        <components.SingleValue {...props}>
            <div className="custom-single-value flex items-center">
                <img src={props.data.icon} alt={props.data.label} style={{ width: 36, height: 36, marginRight: 10 }} />
                <div className='block'>
                    <p className='font-semibold'>{props.data.label}</p>
                    <p className='text-base'>
                        {balance.toFixed(8)}
                        <span className='text-sm text-[#787f91]'>
                            ≈ ${(props.data.value === 'usdt' ? balance : balance * usdPrice).toFixed(2)}
                        </span>
                    </p>
                </div>
            </div>
        </components.SingleValue>
    );
};

const SingleValueNetwork = (props) => (
    <components.SingleValue {...props}>
        <div className="custom-single-value flex items-center">
            <div className='block'>
                <p className='font-semibold'>{props.data.label}</p>
            </div>
        </div>
    </components.SingleValue>
);

// Custom Option component to display the icon and label
const Option = (props) => {
    const balance = Number(props.data.balance) || 0;
    const usdPrice = Number(props.data.usdPrice) || 0;

    return (
        <components.Option {...props}>
            <div className="custom-option flex cursor-pointer w-full items-center">
                <img src={props.data.icon} alt={props.data.label} style={{ width: 30, height: 30, marginRight: 10 }} />
                <div className='block'>
                    <p>{props.data.label}</p>
                    <p className='text-base font-normal'>
                    {(balance * 1).toFixed(8)}
                        <span className='text-sm text-[#787f91] font-normal'> ≈ ${(props.data.value === 'usdt' ? balance : balance * usdPrice).toFixed(2)}</span>
                    </p>
                </div>
            </div>
        </components.Option>
    );
};

const OptionNetwork = (props) => (
    <components.Option {...props}>
        <div className="custom-option flex cursor-pointer w-full items-center">
            <div className='block'>
                <p>{props.data.label}</p>
            </div>
        </div>
    </components.Option>
);

const Deposit = () => {
    const [depositHistory, setDepositHistory] = useState([]);
    const { userData, prices } = useWallet();
    const [currency, setCurrency] = useState(currencyOptions[0]);
    const [network, setNetwork] = useState(networkOptions[currencyOptions[0].value][0]);
    const [fromWallet] = useState('funding');
    const [depositAddress, setDepositAddress] = useState('');
    // eslint-disable-next-line
    const [copyButtonText, setCopyButtonText] = useState('Copy');
    const [minDeposit, setMinDeposit] = useState(currencyOptions[0].min);
    const [blocks, setBlocks] = useState(networkOptions[currencyOptions[0].value][0].block);

    useEffect(() => {
        const fetchDepositHistory = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setDepositHistory(userData.depositHistory || []);
                }
            }
        };

        fetchDepositHistory();
    }, []);

    const getBalance = useCallback((currency, walletType) => {
        if (!userData) return 0;
        return userData.wallets[walletType]?.[currency] || 0;
    }, [userData]);

    const updateCurrencyBalance = useCallback((walletType) => {
        setCurrency(prevCurrency => ({
            ...prevCurrency,
            balance: getBalance(prevCurrency.value, walletType),
            usdPrice: prevCurrency.value === 'usdt' ? 1 : (prices[prevCurrency.value] || 0)
        }));
    }, [getBalance, prices]);

    useEffect(() => {
        // Update currency balance when fromWallet changes or on initial load
        updateCurrencyBalance(fromWallet);
    }, [fromWallet, updateCurrencyBalance]);

    useEffect(() => {
        // Update the network, deposit address, and blocks when the currency changes
        const selectedNetwork = networkOptions[currency.value][0];
        setNetwork(selectedNetwork);
        setDepositAddress(userData?.depositWallets?.[selectedNetwork?.value]?.address || 'Address not found');
        setBlocks(selectedNetwork.block);
    }, [currency, userData]);

    const handleCoinChange = (selectedOption) => {
        setCurrency(selectedOption);
        setMinDeposit(selectedOption.min);
    };

    const handleNetworkChange = (selectedOption) => {
        setNetwork(selectedOption);
        setDepositAddress(userData.depositWallets?.[selectedOption.value]?.address || 'Address not found');
        setBlocks(selectedOption.block);
    }

    const handleCopy = (text, isLink = false) => {
        navigator.clipboard.writeText(depositAddress).then(() => {
            if (text) {
                setCopyButtonText('Copied');
                setTimeout(() => setCopyButtonText('Copy'), 3000);
            } else { }
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };


    return (
        <div className="p-2 md:p-14 bg-[#131315]">
            <div className='block md:flex items-center justify-between'>
                <div>
                    <h1 className='pb-0 md:pb-2 text-2xl md:text-3xl font-bold text-white'>Deposit Crypto</h1>
                    <p className="text-sm font-mediun text-[#787f91]">
                        Select crypto currency and deposit to your account
                    </p>
                </div>
                <div className="ml-auto mt-3 flex justify-end sm:mt-0 sm:justify-start">
                    <Link to='/account/assets/buy-crypto' className="bg-[#01bc8d20] text-sm text-[#01bc8d] font-bold py-2 md:py-2 px-4 rounded-[1rem]">Buy Crypto</Link>
                </div>
            </div>
            <div className='flex justify-center rounded-2xl p-5 mt-6' style={{ border: '2px solid #17181c' }}>
                <div className='flex flex-col w-full gap-y-5 md:w-2/3'>
                    <div className='flex justify-between items-center'>
                        <p className='text-[#787f91] text-sm font-medium w-2/5'>Select a Coin</p>
                        <Select
                            options={currencyOptions.map(option => ({
                                ...option,
                                balance: getBalance(option.value, fromWallet),
                                usdPrice: prices[option.value] || 0,
                            }))}
                            isSearchable={false}
                            value={currency}
                            onChange={handleCoinChange}
                            components={{ SingleValue, Option }}
                            classNamePrefix='react-select'
                            className='react-select-container transition-colors duration-300'
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: '2px solid #3f3f46',
                                    borderRadius: '12px',
                                    backgroundColor: '#131315',
                                    color: '#fff',
                                    padding: '10px 5px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#131315',
                                    border: '2px solid #17181c',
                                    borderRadius: '1rem',
                                    boxShadow: 'inset 0 0 50px #131315',
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    fontWeight: '700',
                                    backgroundColor: state.isSelected ? 'unset' : state.isFocused ? 'unset' : 'unset',
                                    color: state.isSelected ? 'white' : '#787f91',
                                    '&:hover': {
                                        backgroundColor: 'unset',
                                        color: 'white'
                                    },
                                    padding: '0.75rem 0.75rem'
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: 'white',
                                }),
                                menuList: (provided) => ({
                                    ...provided,
                                    '::-webkit-scrollbar': { display: 'none' },
                                    '-ms-overflow-style': 'none',
                                    'scrollbar-width': 'none',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: 'unset',
                                    primary: 'unset',
                                    neutral0: 'unset',
                                    neutral80: 'unset',
                                },
                            })}
                        />
                    </div>
                    <div className='flex justify-between items-center'>
                        <p className='text-[#787f91] text-sm font-medium w-2/5'>Select a Network</p>
                        <Select
                            options={networkOptions[currency.value]}
                            isSearchable={false}
                            value={network}
                            onChange={handleNetworkChange}
                            components={{ SingleValueNetwork, OptionNetwork }}
                            classNamePrefix='react-select'
                            className='react-select-container transition-colors duration-300'
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: '2px solid #3f3f46',
                                    borderRadius: '12px',
                                    backgroundColor: '#131315',
                                    color: '#fff',
                                    padding: '10px 5px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#131315',
                                    border: '2px solid #17181c',
                                    borderRadius: '1rem',
                                    boxShadow: 'inset 0 0 50px #131315',
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    fontWeight: '700',
                                    backgroundColor: state.isSelected ? 'unset' : state.isFocused ? 'unset' : 'unset',
                                    color: state.isSelected ? '#787f91' : '#787f91',
                                    '&:hover': {
                                        backgroundColor: 'unset',
                                        color: 'white'
                                    },
                                    padding: '0.75rem 0.75rem',
                                    cursor: 'pointer'
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: 'white',
                                }),
                                menuList: (provided) => ({
                                    ...provided,
                                    '::-webkit-scrollbar': { display: 'none' },
                                    '-ms-overflow-style': 'none',
                                    'scrollbar-width': 'none',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: 'unset',
                                    primary: 'unset',
                                    neutral0: 'unset',
                                    neutral80: 'unset',
                                },
                            })}
                        />
                    </div>
                    <div className='block'>
                        <p className='text-white text-base font-medium w-full'>Deposit details</p>
                        <p className='text-[#787f91] mt-1 text-sm font-medium w-full'>Scan the QR code or copy the information below to the platform you're withdrawing from</p>
                    </div>
                    <div className='flex justify-center'>
                        <div className='flex bg-white h-[256px] w-[256px] items-center justify-center rounded-2xl'>
                            <img height={200} width={200} alt='qr' src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${depositAddress}`}></img>
                        </div>
                    </div>
                    <div className=''>
                        <p className='text-white text-base font-medium w-full mb-1'>Deposit details</p>
                        <div className='relative flex items-center'>
                            <input
                                readOnly
                                value={depositAddress}
                                className='text-white w-full px-4 py-3 bg-transparent rounded-2xl outline-none pr-16'
                                style={{
                                    border: '2px solid #3f3f46',
                                    backgroundImage: 'none',
                                    appearance: 'textfield',
                                    MozAppearance: 'textfield',
                                    WebkitAppearance: 'none'
                                }}
                            />
                            <button
                                className='absolute right-4 text-sm font-bold text-[#01bc8d]'
                                onClick={handleCopy}
                            >
                                {copyButtonText}
                            </button>
                        </div>

                    </div>
                    <div className='block'>
                        <div className='bg-[#17181c] rounded-2xl block text-left px-6 py-4 w-auto' style={{ border: '2px solid #17181b' }}>
                            <div className='flex justify-between items-center'>
                                <p className='text-[#787f91] font-medium text-sm'>Minimal amount:</p>
                                <p className='text-white font-medium text-sm'>{minDeposit} {(currency.value).toUpperCase()}</p>
                            </div>
                            <div className='flex justify-between items-center'>
                                <p className='text-[#787f91] font-medium text-sm'>Deposit Confirmation:</p>
                                <p className='text-white font-medium text-sm'>{blocks} Block(s)</p>
                            </div>
                            <div className='flex justify-between items-center'>
                                <p className='text-[#787f91] font-medium text-sm'>Withdrawal Confirmation:</p>
                                <p className='text-white font-medium text-sm'>{blocks} Block(s)</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className='bg-[#ea394120] text-sm text-[#ea3941] block justify-center items-center rounded-2xl p-6 w-full md:w-1/2'>
                            Sending coin or token other than {(currency.value).toUpperCase()} in {(network.label).toUpperCase()} network to this address may result in the loss of your deposit.
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className='bg-[#f5a52420] text-sm text-[#f5a524] block justify-center items-center rounded-2xl p-6 w-full md:w-1/2'>
                            Do not send amounts less than the minimum deposit amount, otherwise your transaction will not be found and you will lose funds. Consider the transaction fee.
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-5 bg-[#131315]">
                <div className="block bg-[#131315] py-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181b' }}>
                    <p className=' pl-6 pb-6 text-[#787f91] font-medium text-sm'>Deposit History</p>
                    <div className="flex flex-col">
                        <div className="-m-1.5 p-0 md:p-6 xl:p-0 overflow-x-auto">
                            <div className="p-1.5 min-w-full inline-block align-middle">
                                <div className="overflow-hidden w-full">
                                    <table className="w-full">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="w-64 pb-4 pl-6 text-left text-sm font-bold">Coin</th>
                                                <th scope="col" className="w-64 pb-4 text-left text-sm font-bold hidden sm:table-cell">Time</th>
                                                <th scope="col" className="w-64 pb-4 text-left text-sm font-bold">Amount</th>
                                                <th scope="col" className="w-64 pb-4 text-left text-sm font-bold hidden sm:table-cell">Transaction Hash</th>
                                                <th scope="col" className="w-64 pb-4 text-left text-sm font-bold ">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {depositHistory.map((deposit, index) => (
                                                <tr key={index} className="hover:bg-[#17181b] border-t border-[#17181b] h-16 transition duration-500 w-full">
                                                    <td className="pl-6 sm:pl-6 whitespace-nowrap">{deposit.coin}</td>
                                                    <td className="text-left whitespace-nowrap text-sm font-medium hidden sm:table-cell">{deposit.time}</td>
                                                    <td className="text-left whitespace-nowrap text-sm font-medium">{deposit.amount} {deposit.coin}</td>
                                                    <td className="text-left whitespace-nowrap text-sm font-medium hidden sm:table-cell">{deposit.transactionHash}</td>
                                                    <td className="text-left whitespace-nowrap text-sm font-medium">{deposit.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Deposit;
