import React from 'react';

const UserBar = ({ userData }) => {
  if (!userData) {
    return null;
  }

  const getVerifyClass = (verify) => {
    switch (verify) {
      case 'Verified':
        return 'text-[#01bc8d]';
      case 'Full':
        return 'text-[#01bc8d]';
      case 'Pending':
      case 'Unsent':
      default:
        return 'text-[#f5a524]';
    }
  };

  const getVerifyText = (verify) => {
    switch (verify) {
      case 'Verified':
        return 'Verified';
      case 'Pending':
        return 'Pending';
      case 'Full':
        return 'Verified';
      case 'Unsent':
      default:
        return 'Unverified';
    }
  };

  return (
    <div className="bg-[#17181b] shadow p-8 rounded-2xl mb-4 flex flex-col sm:flex-row flex-wrap items-center sm:items-center sm:space-x-4 text-white">
      <div className="hidden w-full sm:w-auto lg:flex justify-center sm:justify-start mb-4 sm:mb-0">
        <img
          src={`/images/pfp0.svg`}
          alt="User Avatar"
          className="w-16 h-16 rounded-full mr-5"
        />
      </div>
      <div className="flex flex-col sm:flex-row flex-wrap sm:flex-1 sm:justify-between w-full text-center sm:text-left">
        <div className="w-full sm:w-auto flex-1 py-2">
          <p className="font-semibold text-[#787f91] text-xs">Email:</p>
          <p className="text-sm text-white">{userData.email}</p>
        </div>
        <div className="w-full sm:w-auto flex-1 py-2">
          <p className="font-semibold text-[#787f91] text-xs">UID:</p>
          <p className="text-sm text-white">{userData.uid}</p>
        </div>
        <div className="w-full sm:w-auto flex-1 py-2">
          <p className="font-semibold text-[#787f91] text-xs">KYC:</p>
          <p className={`text-sm ${getVerifyClass(userData.verify)}`}>
            {getVerifyText(userData.verify)}
          </p>
        </div>
        <div className="w-full sm:w-auto flex-1 py-2">
          <p className="font-semibold text-[#787f91] text-xs">Security:</p>
          <p className="text-sm text-[#ea3941]">{userData.security}</p>
        </div>
        <div className="w-full sm:w-auto flex-1 py-2">
          <p className="font-semibold text-[#787f91] text-xs">Signup Time:</p>
          <p className="text-sm text-white">{userData.signuptime}</p>
        </div>
        <div className="w-full sm:w-auto flex-1 py-2">
          <p className="font-semibold text-[#787f91] text-xs">Status:</p>
          <p className="text-sm text-white">{userData.status}</p>
        </div>
      </div>
    </div>
  );
};

export default UserBar;
