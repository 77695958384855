import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  const location = useLocation();
  const isHome = location.pathname === '/';
  const isSignUp = location.pathname === '/signup';
  const isLogin = location.pathname === '/login';
  const isBuyCrypto = location.pathname === '/buy-crypto';
  const isFAQ = location.pathname === '/faq';
  const isTerms = location.pathname === '/terms';
  const isTrade = location.pathname === '/trade';
  const isPrivacy = location.pathname === '/privacy';

  return (
    <div className="bg-[#131315] flex">
      {!isHome && !isPrivacy && !isSignUp && !isLogin && !isBuyCrypto && !isFAQ && !isTerms && !isTrade && <Sidebar />}
      <div className="flex flex-col flex-grow px-0 py-0 md:px-0 md:py-0" style={{ width: 'calc(100% - 280px)' }}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
