import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import Skeleton from './Skeleton';

const Referral = () => {
  const [referralCode, setReferralCode] = useState('');
  const [enteredReferralCode, setEnteredReferralCode] = useState('');
  // const [usedReferralCodes, setUsedReferralCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [copyButtonText, setCopyButtonText] = useState('Copy to clipboard');
  const [copyLinkButtonText, setCopyLinkButtonText] = useState('Copy to clipboard');
  const [submitButtonText, setSubmitButtonText] = useState('Submit');
  const [submitError, setSubmitError] = useState('');
  const [referralCodeEntered, setReferralCodeEntered] = useState(false);

  useEffect(() => {
    const fetchReferralData = async (userId) => {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setReferralCode(userData.refcode || ''); // Assuming 'refcode' field in the user document
        // setUsedReferralCodes(userData.usedReferralCodes || []);
        if (userData.enteredReferralCode) {
          setEnteredReferralCode(userData.enteredReferralCode);
          setReferralCodeEntered(true);
        }
      } else {
        console.log('No such document!');
      }
      setLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchReferralData(user.uid);
      } else {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const handleCopy = (text, isLink = false) => {
    navigator.clipboard.writeText(text).then(() => {
      if (isLink) {
        setCopyLinkButtonText('Copied');
        setTimeout(() => setCopyLinkButtonText('Copy to clipboard'), 3000);
      } else {
        setCopyButtonText('Copied');
        setTimeout(() => setCopyButtonText('Copy to clipboard'), 3000);
      }
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleReferralCodeChange = (e) => {
    setEnteredReferralCode(e.target.value);
  };

  const handleSubmitReferralCode = async () => {
    setSubmitError('');
    setSubmitButtonText('Submitting...');

    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentUsedReferralCodes = userData.usedReferralCodes || [];

        if (currentUsedReferralCodes.includes(enteredReferralCode)) {
          setSubmitError('You already used this code!');
          setSubmitButtonText('Submit');
          return;
        }

        await updateDoc(userRef, {
          // usedReferralCodes: [...currentUsedReferralCodes, enteredReferralCode],
          enteredReferralCode: enteredReferralCode
        });

        // setUsedReferralCodes([...currentUsedReferralCodes, enteredReferralCode]);
        setReferralCodeEntered(true);
        setSubmitButtonText('Submit');
      } else {
        setSubmitError('User data not found');
        setSubmitButtonText('Submit');
      }
    } else {
      setSubmitError('User not authenticated');
      setSubmitButtonText('Submit');
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <div className='p-2 md:p-14'>
      <div className='flex items-center justify-between'>
        <div>
          <h1 className='pb-0 md:pb-2 text-2xl md:text-3xl font-bold text-white'>Invite friends</h1>
          <p className="text-sm pb-2 font-mediun text-[#787f91]">
            Share Up to 70% Commission From Your Referees' Trades
          </p>
        </div>
      </div>
      <div className="block bg-[#131315] mb-4 p-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181b' }}>
        <div className='block sm:flex gap-x-6'>
          <div className='bg-[#17181c] rounded-2xl block text-left p-6 w-full sm:w-1/2 mb-6 sm:mb-0' style={{ border: '2px solid #17181b' }}>
            <p className='text-[#787f91] mb-2 font-normal text-base'>Your code</p>
            <p className='font-bold text-base md:text-2xl'>{referralCode}</p>
            <button
              className="bg-[#787f9133] w-full mt-4 rounded-lg p-4 text-sm font-medium duration-500"
              onClick={() => handleCopy(referralCode)}
            >
              {copyButtonText}
            </button>
          </div>
          <div className='bg-[#17181c] rounded-2xl block text-left p-6 w-full sm:w-1/2' style={{ border: '2px solid #17181b' }}>
            <p className='text-[#787f91] mb-2 font-normal text-base'>Your link</p>
            <p className='font-bold text-base md:text-2xl'>{process.env.REACT_APP_LINK_SHORT}rf/{referralCode}</p>
            <button
              className="bg-[#787f9133] w-full mt-4 rounded-lg p-4 text-sm font-medium duration-500"
              onClick={() => handleCopy(`${process.env.REACT_APP_LINK_SHORT}rf/${referralCode}`, true)}
            >
              {copyLinkButtonText}
            </button>
          </div>
        </div>
        <div className='block mt-6 sm:flex gap-x-6'>
          <div className='bg-[#131315] rounded-2xl block text-left p-6 w-full' style={{ border: '2px solid #17181b' }}>
            {(referralCodeEntered ? <p className='text-[#787f91] mb-2 font-normal text-base'>Activated Referral Code</p> : <p className='text-[#787f91] mb-2 font-normal text-base'>Activate Referral Code</p>)}
            <input
            placeholder='Enter referral code'
              className={`w-full my-3 p-4 bg-transparent rounded-2xl outline-none ${(referralCodeEntered && 'text-[#787f91]') || ''}`}
              style={{ border: '2px solid #3f3f46' }}
              value={enteredReferralCode}
              onChange={handleReferralCodeChange}
              disabled={referralCodeEntered}
            />
            {!referralCodeEntered && (
              <button
                className="bg-[#01bc8d33] w-full mt-4 rounded-lg p-4 text-base font-bold duration-500 text-[#01bc8d]"
                onClick={handleSubmitReferralCode}
              >
                {submitButtonText}
              </button>
            )}
            {submitError && <p className="text-[#ea3941] mt-2">{submitError}</p>}
          </div>
        </div>
        <div className='block sm:flex gap-x-8 mt-6'>
          <div className='bg-[#17181c] mb-6 sm:mb-0 rounded-xl block text-left p-6 w-full'>
            <p className='text-[#787f91] mb-2 font-normal text-base'>Total Earnings</p>
            <p className='text-white font-medium text-large'>0 USDT</p>
          </div>
          <div className='bg-[#17181c] mb-6 sm:mb-0 rounded-xl block text-left p-6 w-full'>
            <p className='text-[#787f91] mb-2 font-normal text-base'>Yesterday's Earnings</p>
            <p className='text-white font-medium text-large'>0 USDT</p>
          </div>
          <div className='bg-[#17181c] mb-6 sm:mb-0 rounded-xl block text-left p-6 w-full'>
            <p className='text-[#787f91] mb-2 font-normal text-base'>Friends Confirmed</p>
            <p className='text-white font-medium text-large'>0</p>
          </div>
          <div className='bg-[#17181c] rounded-xl block text-left p-6 w-full'>
            <p className='text-[#787f91] mb-2 font-normal text-base'>Friends Traded</p>
            <p className='text-white font-medium text-large'>0</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Referral;
