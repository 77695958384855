import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useWallet } from './WalletContext';
import Skeleton from './Skeleton';

const Assets = () => {
    const { userData, totalBalanceUsd, btcPrice, prices, loading } = useWallet();
    const [activeTable, setActiveTable] = useState('funding');

    if (loading) {
        return (
            <div className='p-2 md:p-14'>
                <div className='flex items-center justify-between'>
                    <div>
                        <h1 className='text-3xl font-bold text-white'>Discover Valuable Assets</h1>
                    </div>
                </div>
                <div className="pt-5 bg-[#131315]">
                    <div className="block bg-[#131315] mb-4 p-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181b' }}>
                        <Skeleton />
                        <Skeleton />
                    </div>
                </div>
            </div>
        );
    }

    if (!userData) {
        return <p>No user is signed in</p>;
    }

    const calculateWalletUsd = (wallet) => {
        return Object.keys(wallet).reduce((total, currency) => {
            const balance = wallet[currency] || 0;
            // Если валюта - USDT, то считаем как USD 1:1
            const price = currency === 'usdt' ? 1 : (prices[currency] || 0);
            return total + (balance * price);
        }, 0);
    };

    const fundingWalletUsd = calculateWalletUsd(userData.wallets.funding);
    const tradingWalletUsd = calculateWalletUsd(userData.wallets.trading);
    const totalBalanceBtc = totalBalanceUsd / btcPrice;
    const totalFundingBtc = fundingWalletUsd / btcPrice;
    const totalTradingBtc = tradingWalletUsd / btcPrice;

    const sortCurrencies = (wallet) => {
        return Object.keys(wallet).sort((a, b) => {
            const totalA = (wallet[a] || 0) * (a === 'usdt' ? 1 : (prices[a] || 0));
            const totalB = (wallet[b] || 0) * (b === 'usdt' ? 1 : (prices[b] || 0));
            return totalB - totalA; // Сортировка по убыванию
        });
    };

    const sortedFundingCurrencies = sortCurrencies(userData.wallets.funding);
    const sortedTradingCurrencies = sortCurrencies(userData.wallets.trading);

    const renderTable = (wallet, sortedCurrencies) => (
        <table className="w-full">
            <thead>
                <tr className="text-marketList-colName">
                    <th scope="col" className="w-64 pb-5 pl-6 text-left text-sm font-bold">Asset</th>
                    <th scope="col" className="w-64 pb-5 text-left text-sm font-bold">Total</th>
                    <th scope="col" className="w-64 pb-5 text-left text-sm font-bold">Available</th>
                    <th scope="col" className="w-64 pb-5 text-left text-sm font-bold hidden sm:table-cell">USD Value</th>
                    <th scope="col" className="w-64 pb-5 text-left text-sm font-bold hidden sm:table-cell"></th>
                </tr>
            </thead>
            <tbody>
                {sortedCurrencies.map(currency => (
                    <tr key={currency} className="hover:bg-[#17181b] cursor-pointer border-t border-[#17181b] h-16 transition duration-500 w-full">
                        <td className="pl-0 sm:pl-6 whitespace-nowrap">
                            <div className="flex sm:pl-0 pl-6 sm:flex-row gap-y-0 sm:gap-y-2 items-center">
                                <img alt={currency.toUpperCase()} loading="lazy" width="24" height="24" decoding="async" src={`https://dexsobit.com/icons/coinsIcons/${currency}.svg`} style={{ color: 'transparent' }} />
                                <div className="ml-2 text-sm font-semibold hidden sm:block">{currency.toUpperCase()}</div>
                            </div>
                        </td>
                        <td className="text-left whitespace-nowrap text-sm font-medium">{(Number(wallet[currency]) || 0).toFixed(8)}</td>
                        <td className="text-left whitespace-nowrap text-sm font-medium">{(Number(wallet[currency]) || 0).toFixed(8)}</td>
                        <td className="text-left whitespace-nowrap text-sm font-medium hidden sm:table-cell">${(Number(wallet[currency]) * (currency === 'usdt' ? 1 : (prices[currency] || 0))).toFixed(2)}</td>
                        <td className="whitespace-nowrap hidden sm:table-cell">
                            <div className="flex flex-col sm:flex-row gap-x-8 gap-y-6 p-6 sm:p-0 mr-8 justify-end">
                                <Link className="text-sm text-[#01bc8d] font-semibold hover:text-primary/80 duration-500" to="deposit">Deposit</Link>
                                <Link className="text-sm text-[#01bc8d] font-semibold hover:text-primary/80 duration-500" to="withdraw">Withdraw</Link>
                                <Link className="text-sm text-[#01bc8d] font-semibold hover:text-primary/80 duration-500" to="transfer">Transfer</Link>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
    
    return (
        <div className='p-2 md:p-14'>
            <div className='flex items-center justify-between'>
                <div>
                    <h1 className=' text-3xl font-bold text-white'>Discover Valuable Assets</h1>
                </div>
            </div>
            <div className="pt-5 bg-[#131315]">
                <div className="block bg-[#131315] mb-4 p-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181b' }}>
                    <div className='block justify-between items-center'>
                        <h2 className="text-sm font-medium text-[#787f91] mb-2">Total Assets</h2>
                        <div className='flex items-center'>
                            <p className='font-bold text-2xl mr-2'>{totalBalanceBtc.toFixed(8)} BTC </p>
                            <p className='font-normal text-[#787f91] text-sm'>= {totalBalanceUsd.toFixed(2)} USD</p>
                        </div>
                    </div>
                    <div className='block mt-4 sm:flex space-x-0 sm:space-x-4'>
                        <div
                            className={`bg-[#17181c] rounded-2xl block text-left p-6 w-auto sm:w-2/4 cursor-pointer transition-colors duration-300 ${activeTable === 'funding' ? 'border-[#01bc8d] bg-[#01bc8d30]' : 'border-[#23262f]'}`}
                            style={{ borderWidth: '2px', borderStyle: 'solid' }}
                            onClick={() => setActiveTable('funding')}
                        >
                            <p className={`font-semibold text-sm mb-2 ${activeTable === 'funding' ? 'text-[#01bc8d]' : 'text-white'}`}>Funding Wallet</p>
                            <div className='flex items-center'>
                                <p className={`font-bold text-xl mr-2 md:text-2xl ${activeTable === 'funding' ? 'text-[#01bc8d]' : 'text-white'}`}>{totalFundingBtc.toFixed(8)} BTC </p>
                                <p className='font-normal text-[#787f91] text-sm'>= {fundingWalletUsd.toFixed(2)} USD</p>
                            </div>
                        </div>
                        <div
                            className={`bg-[#17181c] rounded-2xl mt-4 sm:mt-0 block text-left p-6 sm:mb-0 w-auto sm:w-2/4 cursor-pointer transition-colors duration-300 ${activeTable === 'trading' ? 'border-[#01bc8d] bg-[#01bc8d30]' : 'border-[#23262f]'}`}
                            style={{ borderWidth: '2px', borderStyle: 'solid' }}
                            onClick={() => setActiveTable('trading')}
                        >
                            <p className={`font-semibold text-sm mb-2 ${activeTable === 'trading' ? 'text-[#01bc8d]' : 'text-white'}`}>Trading Wallet</p>
                            <div className='flex items-center'>
                                <p className={`font-bold text-xl mr-2 md:text-2xl ${activeTable === 'trading' ? 'text-[#01bc8d]' : 'text-white'}`}>{totalTradingBtc.toFixed(8)} BTC </p>
                                <p className='font-normal text-[#787f91] text-sm'>= {tradingWalletUsd.toFixed(2)} USD</p>
                            </div>
                        </div>
                        <div className="flex md:hidden justify-center sm:justify-end space-x-2 w-full pt-6 sm:w-auto">
                            <Link to='/account/assets/deposit' className="bg-[#01bc8d20] flex items-center text-sm text-[#01bc8d] font-bold py-2 px-4 rounded-[1rem] hover:bg-[#20342e] text-center transition-colors duration-300 md:p-4">Deposit</Link>
                            <Link to='/account/assets/withdraw' className="bg-[#01bc8d20] flex items-center text-sm text-[#01bc8d] font-bold py-2 px-4 rounded-[1rem] hover:bg-[#20342e] transition-colors duration-300 md:p-4">Withdraw</Link>
                            <Link to='/account/assets/transfer' className="bg-[#01bc8d20] flex items-center text-sm text-[#01bc8d] font-bold py-2 px-4 rounded-[1rem] hover:bg-[#20342e] text-center transition-colors duration-300 md:p-4">Transfer</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#131315]">
                <div className="block bg-[#131315] py-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181b' }}>
                    <div className="flex flex-col">
                        <div className="-m-1.5 p-0 md:p-6 xl:p-0 overflow-x-auto">
                            <div className="p-1.5 min-w-full inline-block align-middle">
                                <div className="overflow-hidden w-full">
                                    {activeTable === 'funding' && renderTable(userData.wallets.funding, sortedFundingCurrencies)}
                                    {activeTable === 'trading' && renderTable(userData.wallets.trading, sortedTradingCurrencies)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Assets;
