import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const currencies = ['btc', 'eth', 'usdc', 'ltc', 'trx', 'busd', 'doge', 'bnb', 'xrp', 'usdt'];

const WalletBar = ({ userData }) => {
    const [prices, setPrices] = useState({});
    const [btcPrice, setBtcPrice] = useState(null);
    const [totalBalanceBtc, setTotalBalanceBtc] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const responses = await Promise.all(
                    currencies.filter(currency => currency !== 'usdt').map(currency => 
                        fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${currency.toUpperCase()}USDT`)
                    )
                );
                const data = await Promise.all(responses.map(response => response.json()));
                const prices = data.reduce((acc, item, index) => {
                    acc[currencies[index]] = parseFloat(item.price);
                    return acc;
                }, {});
                setPrices(prices);
            } catch (error) {
                console.error('Failed to fetch prices:', error);
            }
        };

        fetchPrices();
    }, []);

    useEffect(() => {
        const fetchBtcPrice = async () => {
            try {
                const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT');
                const data = await response.json();
                setBtcPrice(parseFloat(data.price));
            } catch (error) {
                console.error('Failed to fetch BTC price:', error);
            }
        };

        fetchBtcPrice();
    }, []);

    useEffect(() => {
        if (Object.keys(prices).length > 0 && btcPrice !== null && userData) {
            const totalBtc = currencies.reduce((total, currency) => {
                const fundingBalance = userData.wallets.funding[currency] || 0;
                const tradingBalance = userData.wallets.trading[currency] || 0;
                const currencyBalance = fundingBalance + tradingBalance;
                let currencyValueUsd = 0;

                if (currency === 'usdt') {
                    currencyValueUsd = currencyBalance; // USDT is 1:1 with USD
                } else {
                    currencyValueUsd = currencyBalance * (prices[currency] || 0);
                }

                const currencyValueBtc = currency === 'btc'
                    ? currencyBalance
                    : currencyValueUsd / (btcPrice || 1); // Avoid division by zero

                return total + currencyValueBtc;
            }, 0);

            setTotalBalanceBtc(Number(totalBtc)); // Ensure it's a number
            setLoading(false);
        }
    }, [prices, btcPrice, userData]);

    const totalBalanceBtcDisplay = Number(totalBalanceBtc).toFixed(8);
    const totalBalanceUsdDisplay = Number(totalBalanceBtc * (btcPrice || 0)).toFixed(2);

    return (
        <div className="rounded-2xl py-8 px-6 mb-4 flex flex-col sm:flex-row justify-between items-center text-white" style={{ background: 'linear-gradient(105.4deg, rgba(1, 188, 76, 0.12) 0%, rgba(1, 188, 53, 0.04) 98.37%)', border: '2px solid #01bc8d33' }}>
            <div className="mb-4 sm:mb-0 sm:text-left text-center">
                <p className="text-sm mb-1 font-normal text-[#787f91]">Total Assets</p>
                <div className='flex items-center'>
                    <p className="text-2xl font-bold text-white mr-2">{loading ? '0.00000000' : totalBalanceBtcDisplay} BTC</p>
                    <p className="text-[#787f91] text-base font-medium">≈ {loading ? '0.00' : totalBalanceUsdDisplay} USD</p>
                </div>
            </div>
            <div className="flex justify-center sm:justify-end space-x-2 w-full sm:w-auto">
                <Link to='/account/assets/deposit' className="bg-[#54b990] flex items-center text-sm text-white font-bold py-2 px-4 rounded-[1rem] hover:bg-[#4ea984] transition-colors duration-300 md:p-4">Deposit</Link>
                <Link to='/account/assets/buy-crypto' className="bg-[#01bc8d20] flex items-center text-sm text-[#01bc8d] font-bold py-2 px-4 rounded-[1rem] hover:bg-[#20342e] text-center transition-colors duration-300 md:p-4">Buy Crypto</Link>
                <Link to='/account/assets/withdraw' className="bg-[#01bc8d20] flex items-center text-sm text-[#01bc8d] font-bold py-2 px-4 rounded-[1rem] hover:bg-[#20342e] transition-colors duration-300 md:p-4">Withdraw</Link>
            </div>
        </div>
    );
};

export default WalletBar;
