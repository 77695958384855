import React from 'react';
import { Link } from 'react-router-dom';
import './animations.css'; // Import the CSS file

const PromoBlock = () => {
  return (
    <div className="bg-gradient-to-r from-blue-400 to-purple-400 h-auto md:h-36 flex items-center px-4 sm:px-6 lg:px-8 py-8 md:py-0">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between w-full">
        <div className="text-center md:text-left mb-4 md:mb-0 animate-left animate-delay-1">
          <h2 className="text-2xl font-extrabold text-white sm:text-3xl">
            Earn up to $25 worth of crypto
          </h2>
          <p className="mt-2 text-base text-white">
            Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.
          </p>
        </div>
        <div className="flex justify-center md:justify-end mt-4 md:mt-0 animate-right animate-delay-2">
          <Link
            to="/signup"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-full text-black bg-white hover:bg-gray-100"
          >
            Create Account
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PromoBlock;
