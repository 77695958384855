import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';

const SecurityBar = () => {
  const [lastPasswordChange, setLastPasswordChange] = useState('');
  const [lastLogin, setLastLogin] = useState('');
  const [kycStatus, setKycStatus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setKycStatus(userData.verify || 'Unverified');
          setLastPasswordChange(userData.lastPasswordChange || userData.signuptime);

          if (userData.recentLogins && userData.recentLogins.length > 0) {
            setLastLogin(userData.recentLogins[0].time || userData.signuptime);
          } else {
            setLastLogin(userData.signuptime);
          }
        }
      }
    };

    fetchData();
  }, []);

  const formatKycStatus = (status) => {
    if (status) {
      const statusLower = status.toLowerCase();
      return statusLower.charAt(0).toUpperCase() + statusLower.slice(1);
    }
    return 'Unverified';
  };

  const getKycStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'verified':
        return '#01bc8d'; // зеленый
      case 'full':
        return '#01bc8d'; // зеленый
      case 'pending':
        return '#f5a524'; // желтый
      default:
        return '#f5a524'; // желтый для всех других значений
    }
  };

  const getVerifyText = (verify) => {
    switch (verify) {
      case 'Verified':
        return 'Verified';
      case 'Pending':
        return 'Pending';
      case 'Full':
        return 'Verified'; // Изменено для отображения "Verified" вместо "Full"
      case 'Unsent':
      default:
        return 'Unverified';
    }
  };
  

  return (
    <div className="block bg-[#131315] mb-4 p-6 shadow-md text-white rounded-2xl" style={{ border: '2px solid #17181c' }}>
      <div className='flex justify-between items-center'>
        <h2 className="text-sm font-medium text-[#787f91]">Security Settings</h2>
        <Link to='/account/security' className="bg-[#01bc8d20] text-sm text-[#01bc8d] font-bold py-2 px-4 rounded-[1rem] hover:bg-[#20342e] hover:border-[#2b4f41] transition-colors duration-300">Security Center</Link>
      </div>
      <div className='block mt-4 sm:flex space-x-0 sm:space-x-4'>
        <Link to='/account/kyc' className='flex items-center rounded-2xl text-left p-6 w-auto sm:w-2/4 transition-all duration-300 hover:bg-[#17181c]' style={{ border: '2px solid #17181b' }}>
          <svg stroke="#787f91" fill="#787f91" strokeWidth="0" viewBox="0 0 24 24" className="text-2xl text-textSecondary" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"></path></svg>
          <div className='ml-3'>
            <p className='font-semibold text-lg'>Identity Verification</p>
            <p className='font-normal text-sm' style={{ color: getKycStatusColor(kycStatus) }}>{getVerifyText(kycStatus)}</p>
          </div>
        </Link>
        <Link to='/account/security/password' className='flex items-center rounded-2xl text-left p-6 w-auto sm:w-2/4 transition-all duration-300 hover:bg-[#17181c]' style={{ border: '2px solid #17181b' }}>
          <svg stroke="#787f91" fill="#787f91" strokeWidth="0" viewBox="0 0 256 256" className="text-2xl text-textSecondary" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M216,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM64,168a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Zm73.3-39.81-12.36,4,7.64,10.5a8,8,0,1,1-13,9.41L112,141.61l-7.63,10.51a8,8,0,1,1-13-9.41l7.64-10.5-12.36-4a8,8,0,1,1,5-15.21L104,117V104a8,8,0,0,1,16,0v13l12.35-4a8,8,0,1,1,5,15.21Zm72,0-12.36,4,7.64,10.5a8,8,0,1,1-13,9.41L184,141.61l-7.63,10.51a8,8,0,1,1-13-9.41l7.64-10.5-12.36-4a8,8,0,1,1,5-15.21L176,117V104a8,8,0,0,1,16,0v13l12.35-4a8,8,0,0,1,5,15.21Z"></path></svg>
          <div className='ml-3'>
            <p className='font-semibold text-lg'>Password</p>
            <p className='font-normal text-sm'>Last Change: {lastPasswordChange}</p>
          </div>
        </Link>
        <Link to='/account/security/' className='rounded-2xl flex items-center text-left p-6 mb-4 mt-4 sm:mt-0 sm:mb-0 w-auto sm:w-2/4 transition-all duration-300 hover:bg-[#17181c]' style={{ border: '2px solid #17181b' }}>
          <svg stroke="#787f91" fill="#787f91" strokeWidth="0" viewBox="0 0 1024 1024" className="text-2xl text-textSecondary" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 0 1 520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 0 1 270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 0 1 0 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z"></path></svg>
          <div className='ml-3'>
            <p className='font-semibold text-lg'>Recent logins</p>
            <p className='font-normal text-sm'>Last Login: {lastLogin}</p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SecurityBar;
