import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig'; // Make sure this import matches your actual firebase configuration file
import { useWallet } from './WalletContext';
import UserBar from './dashboard/UserBar';
import WalletBar from './dashboard/WalletBar';
import KYCBar from './dashboard/KYCBar';
import TradingFeesBar from './dashboard/TradingFeesBar';
import SecurityBar from './dashboard/SecurityBar';
import ReferralBar from './dashboard/ReferralBar';
import Skeleton from './Skeleton';
import VoucherBar from './dashboard/VoucherBar';

const Dashboard = () => {
  const { userData, loading } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }
    });
    return unsubscribe;
  }, [navigate]);

  if (loading) {
    return (
      <div className='p-2 md:p-14' style={{ width: '100%' }}>
        <div className='animate-pulse'>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    );
  }

  if (!userData) {
    return <p>No user is signed in</p>;
  }

  const { kyc } = userData;

  return (
    <div className='p-2 md:p-6' style={{ width: '100%' }}>
      <UserBar userData={userData} />
      <VoucherBar />
      <WalletBar userData={userData} />
      {kyc?.status !== 'Full' && <KYCBar />}
      <TradingFeesBar />
      <SecurityBar userData={userData} />
      <ReferralBar userData={userData} />
    </div>
  );
};

export default Dashboard;
