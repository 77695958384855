import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';

const Password = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChangePassword = async () => {
        setError('');
        setSuccess('');

        if (newPassword !== repeatNewPassword) {
            setError('New passwords do not match');
            return;
        }

        const user = auth.currentUser;
        if (user) {
            const credential = EmailAuthProvider.credential(user.email, currentPassword);

            try {
                await reauthenticateWithCredential(user, credential);
                await updatePassword(user, newPassword);

                const userRef = doc(db, 'users', user.uid);
                const currentTime = new Date().toLocaleString('en-GB');

                await updateDoc(userRef, {
                    lastPasswordChange: currentTime
                });

                setSuccess('Password updated successfully');
            } catch (error) {
                setError(error.message);
            }
        } else {
            setError('User not authenticated');
        }
    };

    return (
        <div className='p-2 md:p-14'>
            <div className='flex items-center justify-between pb-2'>
                <div className='block md:flex justify-between items-center w-full'>
                    <div>
                        <h1 className='pb-0 md:pb-2 text-2xl md:text-3xl font-bold text-white'>Password</h1>
                        <p className="text-sm font-mediun text-[#787f91]">
                            Always choose a more complex password for better security
                        </p>
                    </div>
                    <div className="ml-auto mt-3 flex justify-end sm:mt-0 sm:justify-start">
                        <Link to='/account/security' className="bg-[#01bc8d20] text-sm text-[#01bc8d] font-bold py-4 px-4 flex rounded-[1rem] hover:bg-[#20342e] transition-colors duration-300">
                            <svg stroke="currentColor" fill="#01bc8d" strokeWidth="0" viewBox="0 0 512 512" className="mr-2 text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M70.5 265.4l59.6-59.4c2.6-2.6 6.1-4.1 9.9-4.1 3.7 0 7.3 1.4 9.9 4.1 2.6 2.6 4.1 6.1 4.1 9.9s-1.5 7.3-4.1 9.9l-.1.1-41.1 40.1H370c13.2 0 25.8-5.2 35.3-14.7 9.5-9.4 14.7-21.9 14.7-35.3v-48c0-7.7 6.3-14 14-14s14 6.3 14 14v48c0 20.8-8.1 40.3-22.9 55.1-14.8 14.8-34.3 22.9-55.1 22.9H108.3l39.6 40.2c2.6 2.6 4.1 6.1 4.1 9.9 0 3.7-1.4 7.3-4.1 9.9l-.1.1c-2.7 2.5-6.2 3.9-9.8 3.9-3.9 0-7.3-1.4-9.9-4.1l-57.6-57.4c-4.2-4.2-6.5-9.8-6.5-15.7 0-5.8 2.3-11.3 6.5-15.4z"></path></svg>
                            Back to Security
                        </Link>
                    </div>
                </div>
            </div>
            <div className=" bg-[#131315] mb-4 block p-10 shadow-md text-white rounded-2xl items-center" style={{ border: '2px solid #17181b' }}>
                <div className='block'>
                    <div className='flex justify-center'>
                        <div className='flex flex-col w-full gap-y-5 md:w-1/2'>
                            <div className='flex justify-between items-center'>
                                <input
                                    type='password'
                                    placeholder='Current password'
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    className='w-full p-4 bg-transparent rounded-2xl outline-none'
                                    style={{ border: '2px solid #3f3f46' }}
                                />
                            </div>
                            <div className='flex justify-between items-center'>
                                <input
                                    type='password'
                                    placeholder='New password'
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className='w-full p-4 bg-transparent rounded-2xl outline-none'
                                    style={{ border: '2px solid #3f3f46' }}
                                />
                            </div>
                            <div className='flex justify-between items-center'>
                                <input
                                    type='password'
                                    placeholder='Repeat new password'
                                    value={repeatNewPassword}
                                    onChange={(e) => setRepeatNewPassword(e.target.value)}
                                    className='w-full p-4 bg-transparent rounded-2xl outline-none'
                                    style={{ border: '2px solid #3f3f46' }}
                                />
                            </div>
                        </div>
                    </div>
                    {error && <p className="text-[#ea3941] text-center mt-2">{error}</p>}
                    {success && <p className="text-[#01bc8d] text-center mt-2">{success}</p>}
                    <div className='flex justify-center'>
                        <button
                            onClick={handleChangePassword}
                            className="bg-[#01bc8d33] w-full md:w-1/2 mt-5 rounded-2xl p-4 text-base font-bold duration-500 text-[#01bc8d]"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Password;
