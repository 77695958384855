import React from 'react';

const currentBTC = 0;

const TradingFeesBar = () => {
  return (
    <div className="bg-[#131315] rounded-2xl p-6 mb-4 block sm:flex-row justify-between items-center text-white" style={{ border: '2px solid #17181b' }}>
      <div className="sm:mb-0 sm:text-left text-center">
        <p className="text-sm font-normal text-[#787f91]">Trading Fees</p>
      </div>
      <div className="mt-4 sm:mb-0 sm:text-left text-center flex justify-between items-center">
        <div className='flex items-center'>
        <svg stroke="currentColor" fill="#54b990" strokeWidth="0" viewBox="0 0 24 24" className=" mr-2 text-primary" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M2.00488 19H22.0049V21H2.00488V19ZM2.00488 5L7.00488 8L12.0049 2L17.0049 8L22.0049 5V17H2.00488V5Z"></path></svg>
          <p className="text-2xl font-bold text-white">Lv. 1</p>
        </div>
        <div className='flex items-center'>
          <div className='pr-3'>
            <p className="text-left font-normal text-[#787f91] text-sm pb-1">Maker</p>
            <p className="text-left  text-sm font-medium text-white">0.085%
            </p>
          </div>
          <div>
            <p className="text-left  font-normal text-[#787f91] text-sm pb-1">Taker</p>
            <p className="text-left  text-sm font-medium text-white">0.085%
            </p>
          </div>
        </div>
      </div>
      <div className="mt-12 sm:mb-0 sm:text-left text-center flex justify-between items-center">
        <div className='pr-3'>
          <p className="text-sm text-white">0.00 BTC</p>
        </div>
        <div>
          <p className="text-sm text-white">50 BTC</p>
        </div>
      </div>
      <div className="h-2 bg-gray-600 rounded-md mt-1">
        <div
          className="bg-[#01bc8d] h-full rounded-md"
          style={{ width: `${(currentBTC / 50) * 100}%` }}
        ></div>
      </div>
      <div className="mt-2 sm:mb-0 sm:text-left text-center flex justify-between items-center">
        <div className='pr-3'>
          <p className="font-normal text-[#787f91] text-sm pb-1">Lv.1</p>
        </div>
        <div className='pr-3'>
          <p className="hidden font-normal text-[#787f91] text-sm pb-1 sm:block">Increase trading volume by 50 BTC to upgrade</p>
        </div>
        <div>
          <p className="font-normal text-[#787f91] text-sm pb-1">Lv. 2</p>
        </div>
      </div>
    </div>
  );
};

export default TradingFeesBar;
